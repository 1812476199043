
// @media screen and (max-width: 912px) {
//   .middle_section {
//   width: 65% !important; 
//   }
//   .about-mob-center{
//     width: 100% !important;
//     margin-left: 3rem !important;
//   }
//   .footer_section{
  
//     padding: 2rem !important;
  
//   }

// .about{
//   align-self: center;
//   text-align: center;
// } 

  
// }
// @media screen and (max-width: 550px) {
//   .middle_section {
//     width: 100% !important; 
//   }
//   .footer_section{
//     width: 100% !important; 
//     height: 100% !important; 
//     margin-bottom: 20px !important;
//   }
//   .about-mob-center{
//     width: 60% !important;
//    align-items: center;
//    align-self: "center";
//    margin-right: 30rem !important;
  
//   }
//   .goal_section {
//     margin-left: 2rem !important;
//   }
//   .card{
//    align-items: center;
//    align-self: "center";
//    margin-right: 1rem !important;
//    padding-bottom: 20rem !important;
//   }
  
//   .card_footer_section{
//     height: 70vh !important;
  
//   }
//   .middle-card{
//     height: 70vh !important;
    
//   }
  
// }
// @media screen and (max-width: 414px) {
//   .footer_section{
//     width: 100% !important; 
//     height: 100% !important; 
//   }
 
  
// }


@media screen and (max-width: 912px) {
  .aboutUs_middle_section {
    width: 100% !important;
    height: 27vh !important;
   
  }
  .about-us {
    width: 50vh !important;
  
  }
  .aboutUs_section_text{
    width: 24vh !important;
  }
  .footer_section {
   margin-bottom: 4rem !important;  
  }
  .about {
    align-self: center;
    text-align: center;
  }
    .card_height{ 
    height: 30vh !important;
    margin-bottom: 2rem !important;
    
   
  }
  .left_text{
  
    width: 10vh !important;
    margin-left: 2rem !important;
    margin-right: 2rem !important;
    margin-top: 2rem !important;

  
  }
  .right_text{
  
    width: 60vh !important;
    margin-left: 10rem !important;
    margin-top: 2rem !important;
  }
  .middle_section{
    height: 30vh !important;
  }
  
}
@media only screen and (max-width: 840px) {
  .card_height{ 
    height: 40vh !important;
    margin-top: 1rem !important;
    margin-left: 2rem !important;
    margin-bottom: 2rem !important;
   
  }
  
  .middle_section {
    width: 100% !important;
    height: 41vh !important;
  }
 
}
@media only screen and (max-width: 768px) {
  .card_height{ 
    height: 40vh !important;
    margin-top: 1rem !important;
    margin-left: 2rem !important;
    margin-bottom: 2rem !important;
   
  }
  
  .middle_section {
    width: 100% !important;
    height: 41vh !important;
  }
  .aboutUs_section_text{
    width: 28vh !important;
  }
}

@media screen and (max-width: 550px) {
  .aboutUs_middle_section {
    width: 100% !important;
    height: 50vh !important;
   
    ;
  }
  .footer_section {
    width: 100% !important;
    height: 100% !important;
    margin-bottom: 20px !important;
  }
  .aboutUs_Logo{
margin-left: 1rem !important;
  }
  .about-mob-center {
    width: 60% !important;
    align-items: center;
    align-self: center;
    margin-right: 30rem !important;
  }
  .goal_section {
    margin-left: 2rem !important;
  }
  .card {
    align-items: center;
    align-self: center;
    margin-right: 1rem !important;
    padding-bottom: 20rem !important;
  }
  .aboutUs_section_text{
    width: 24vh !important;
  }
  .left_text{
  
    width: 10vh !important;
   
  
  }
  .right_text{
  
    width: 50vh !important;
   
  }
  .card_footer_section {
    height: 70vh !important;
  }
  .middle-card {
    height: 70vh !important;
  }
  .card_height{ 
    height: 60vh !important;
    margin-top: 1rem !important;
    margin-left: 2rem !important;
    margin-bottom: 2rem !important;
   
  }
  .content{
    height: 150vh !important;

  }
  .middle_section{
    height: 65vh !important;
  }
}
@media screen and (max-width: 414px) {
  .footer_section {
    width: 100% !important;
    height: 10% !important;
  }
  .card_height{ 
   height: 50vh !important;
    margin-top: 1rem !important;
    margin-bottom: 2rem !important;
   justify-content:"center";
   align-items: "center";
   align-content: "center";
   margin-left: 1rem !important;
  
   
  }
  .left_text{
  
    width: 10vh !important;
    margin-left: 2rem !important;
    margin-right: 3rem !important;

  
  }
  .right_text{
  margin-left: -0rem !important;
    width: 40vh !important;
    margin-top: 0rem !important;
  }
  .middle_section{
    height: 50vh !important;
  }
  .about_us_Text{
    font-size: 18px !important;
    
   }
  .card_content{
    justify-content:"center";
    align-items: "center";
    align-content: "center";
    margin-left: 1rem !important;
  }
  .content{
    height: 130vh !important;
  }
}


@media screen and (max-width: 380px) {
  .footer_section {
    width: 100% !important;
    height: 100% !important;
  }
  .card_height{ 
   height: 50vh !important;
    margin-top: 1rem !important;
    margin-bottom: 2rem !important;
   justify-content:"center";
   align-items: "center";
   align-content: "center";
   margin-left: 1rem !important;
  
   
  }
  .card_content{
    justify-content:"center";
    align-items: "center";
    align-content: "center";
    margin-left: 1rem !important;
  }
  .content{
    height: 130vh !important;
  }
  .left_text{
  
    width: 10vh !important;
    margin-left: 2rem !important;
    margin-right: 3rem !important;

  
  }
  .middle_section{
    height: 65vh !important;
  }
  .right_text{
    margin-left: -0rem !important;
    width: 50vh !important;
    margin-top: 0rem !important;
  }
}


