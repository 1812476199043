/**fonts**/
@font-face {
  font-family: "Metropolis-Black";
  src: local("Metropolis"),
    url(../website/assets/fonts/Metropolis-Black.woff2) format("woff2");
}

@font-face {
  font-family: "Metropolis-Medium";
  src: local("Metropolis"),
    url(../website/assets/fonts/Metropolis-Medium.woff2) format("woff2");
}
@font-face {
  font-family: "Metropolis";
  src: local("Metropolis"),
    url(../website/assets/fonts/Metropolis-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "SofiaProMedium";
  src: local("SofiaProMedium"),
    url(../website/assets/fonts/SofiaProMedium.woff2) format("woff2");
}
@font-face {
  font-family: "SofiaProRegular";
  src: local("SofiaProRegular"),
    url(../website/assets/fonts/SofiaProMedium.woff2) format("woff2");
}
@font-face {
  font-family: "SofiaProBold";
  src: local("SofiaProBold"),
    url(../website/assets/fonts/SofiaProBold.woff2) format("woff2");
}
@font-face {
  font-family: "SofiaProSemiBold";
  src: local("SofiaProSemiBold"),
    url(../website/assets/fonts/SofiaProSemiBold.woff2) format("woff2");
}
@import "config";
@import "colors";
@import "bootstrap";
@import "fonts-color";
@import "functions";
@import "mixins";
@import "variables";

* {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: auto;
}

html {
  background-color: #f5f7fa;
  overflow: auto;
  height: 100%;
  font-family: sans-serif, "Source Sans Pro", Metropolis-Medium;
  -webkit-text-size-adjust: 100%;
}

body {
  height: 100%;
  background-color: #f5f7fa;
}
.container-fluid {
  padding: 0 !important;
  height: auto;
}
#root,
.app,
.container {
  background-color: #f5f7fa;
  overflow-x: hidden;
  scrollbar-width: thin;
}
.container,
.db_container {
  max-width: 1660px;
  position: relative;
}
// ::-webkit-scrollbar {
//   width: 0px !important;
// }
/******1em=20px********/
main {
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}
link {
  text-decoration: none;
}
a {
  text-decoration: none;
}

a:hover,
a:focus,
a:active,
link:hover,
link:focus,
link:active {
  text-decoration: none !important;
  border: none !important;
}
ul {
  list-style-type: none;
}
h1 {
  font-family: SofiaProMedium;
  font-style: normal;
  font-weight: 400;
  font-size: 2.2em;
  line-height: 48px;
  /* identical to box height */

  /* Black */

  color: #071232;
}
h2 {
  font-family: SofiaProMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 104.6%;
  /* identical to box height, or 42px */

  /* Blue */

  color: $primary-blue;
}
h3 {
  font-family: SofiaProMedium;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  /* identical to box height */

  /* Black */

  color: #071232;
  margin-bottom: 0;
}
h5 {
  font-family: SofiaProMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  /* Label & subheading */

  color: #8e919c;
}
button {
  border: none;
}
button:active,
button:focus,
button:hover {
  outline: none !important;
}
input[type="text"],
input[type="email"],
input[type="password"],
input[type="phone"],
textarea {
  -webkit-appearance: none;
}
input::placeholder {
  font-size: 0.76em;
  color: #dcdcdc;
}
// Thumb scrollbar
::-webkit-scrollbar {
  width: 12px;
  background-color: #dfe8fc;
  border-radius: 15px;
}
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track-piece {
  background-clip: padding-box;
  border: 3px solid transparent;
  border-radius: 15px;
}
::-webkit-scrollbar-thumb {
  background-color: #60677b;
}
::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
// Thumb scrollbar end
.dark_placeholder {
  input::placeholder {
    color: #071232 !important;
    font-family: SofiaProRegular !important;
    font-weight: 500 !important;
  }
}
// .page-min {
//   min-height: calc(100vh - 200px);
// }

.page-min {
  min-height: 100vh !important;
}
.add-rooms {
  background-color: #245de8;
  color: white;
  width: 200px;
  height: 40px;
  border-radius: 10px;
}
.db_addroomtab {
  background: rgba(223, 232, 252, 0.3);
  border-radius: 15px;
}

.checkbox_text {
  color: #2254d3;
  font-size: 12px;
  line-height: 18px;
  margin-left: -2em;
  font-family: SofiaProMedium;
}
.checkbox_input {
  height: 24px;
}
@include media-breakpoint-up(lg) {
  .page-max {
    max-height: calc(100vh - 200px);
  }
}
textarea {
  max-width: 80%;
}
.fade-in {
  animation: fade 0.5s forwards;
  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.slide-down {
  animation: slidedown 0.5s forwards;
  @keyframes slidedown {
    from {
      top: 10px;
    }
    to {
      top: 20px;
    }
  }
}
.slide-right {
  &:hover {
    animation: slideright 0.5s forwards;
  }

  @keyframes slideright {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(15px);
    }
  }
}
.ease {
  transition: all 0.3s ease;
}
.glitch-in {
  &:hover {
    animation: fadein 1s forwards;
  }

  @keyframes fadein {
    from {
      opacity: 0.7;
    }
    to {
      opacity: 1;
    }
  }
}

.shake {
  &:hover {
    animation: shakeShake 0.5s forwards;
  }

  @keyframes shakeShake {
    0% {
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(3deg);
    }
    50% {
      transform: rotate(-3deg);
    }
    75% {
      transform: rotate(3deg);
    }

    100% {
      transform: rotate(0);
    }
  }
}
.scale-in-out {
  .blog-item-title {
    transition: all 0.4s ease;
  }
  &:hover {
    .blog-item-image {
      animation: scaleInOut 0.4s forwards;
    }
    .blog-item-title {
      text-decoration: underline !important;
    }
  }

  @keyframes scaleInOut {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(1.05);
    }
  }
}
.slide-right-container {
  &:hover {
    svg {
      animation: slideright 0.5s forwards;
    }
  }
}
/*******************************Loading Page / Screen Styles**************/
.loading_screen_fixed {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999999999999999;
  width: 100vw !important;
  height: 100vh;
  min-height: 100vh;
  background: rgba(0, 24, 57, 0.35) !important;
  svg {
    width: 6em;
    height: 6em;
  }
}
.underline {
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}
.blue_text {
  color: $primary-blue;
}
.thc_logo {
  animation: rotating 0.7s linear infinite;
  margin-top: 4em;
}

@keyframes rotating {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
  50% {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.loading_page {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
}
.loading_page svg:first-child {
  margin-right: 1.3em;
}
.loading_screen {
  width: 100%;
  height: 100vh;
  display: flex;

  justify-content: center;
  background: transparent;
}
.grid_third {
  display: grid;
  place-content: center;

  grid-gap: 15px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  @media screen and (min-width:1200px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-auto-columns: minmax(0, 1fr);
  }
  @media screen and (max-width:1199px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-auto-columns: minmax(0, 1fr);
  }
  @media screen and (max-width:1028px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-auto-columns: minmax(0, 1fr);
  }

  @media screen and (max-width:440px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-auto-columns: minmax(0, 1fr);
  }
}
.swiper-slide,
.swiper-wrapper {
  width: rfs(520, 270) !important;
  margin: 0 auto !important;
}
.swiper-wrapper {
  overflow: hidden;
}

/** Alert styles **/
.app_alert_success {
  box-shadow: 0 3px 0.5px 0.5px rgba(84, 211, 77, 0.7);
}
.app_alert_error {
  box-shadow: 0 3px 0.5px 0.5px rgba(235, 55, 55, 0.7);
}
.app_alert_info {
  box-shadow: 0 3px 0.5px 0.5px rgba(252, 227, 0, 0.7);
}
.app_alert {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #071232;
  border-radius: 5px;
  width: 300px;
  height: fit-content;
  font-size: 0.76em;
  font-weight: bold;
  color: white;
  padding: 10px;
  span {
    margin: 0 10px 0 0;
  }
  button {
    padding: 0;
    margin: 0 0 0 10px;
    background: none;
    color: white;
    height: fit-content;
    width: fit-content;
  }
}
.inputTags {
  text-align: left;
  align-self: flex-start;
  font-size: 0.66em;
}
.loginForm {
  width: 100%;

  margin-top: 5em;
  margin-bottom: 150px;
  margin-right: auto;
  margin-left: auto;
  width: 82%;
}
.loginFormLeft {
  padding-left: 0.5em;
}
.SignupFormRight {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  padding-top: 4.9em;
  padding-right: 6em;
}
.row {
  height: auto;
}

.loginCard {
  width: 100%;
  height: 404.92px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  float: right;
  /* Card border */

  border: 1px solid #dfe8fc;
  box-sizing: border-box;
  box-shadow: 0px 8px 19px rgba(34, 84, 211, 0.15);
  border-radius: 0.9em;
}
.loginCardBody,
.loginCardBody > form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
button {
  width: 100%;
}
.practiceBtn {
  width: 75%;
  background: $primary-blue;
  border-radius: 0.45em;

  height: 2.4em;
  /* identical to box height */

  border: none;
  margin-top: 40px;
  margin-bottom: 100px;
}
.practiceBtn > h3 {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
}
.loginCardHead > div > span {
  background: #dfe8fc;
  border-radius: 0.7em;
  width: 2.4em;
  height: 2.4em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
.loginCardHead > div > span > img {
  width: 1.1em;
  height: 0.6em;
  z-index: 999;
}
.loginCardHead {
  height: 4.2em;
  border-bottom: 1px solid #e6e8ec;
  width: 100%;
  justify-content: center;
  display: flex;
  margin-bottom: 7px;
}
.loginCardHead > div {
  align-items: center;
  height: 100%;
  width: 90%;
}
input {
  width: 100%;
  height: 2.4em;
  background: #ffffff;
  /* Card border */

  border: 1px solid #dfe8fc;
  box-sizing: border-box;
  border-radius: 0.24em;
  padding: 0;
  padding-left: 20px;
  padding-right: 20px;
}
input:active,
input:focus,
input:hover {
  outline: none;
  border: 1px solid $primary-blue;
}
button:active,
button:focus {
  outline: none;
}
.signup_links > a {
  width: 100%;
}
.inputField {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40px;
}
.passwordForm {
  border: none;
  height: 100%;
}
.passwordInp {
  border: none;
  padding-top: 0;
  padding-bottom: 0;
}
.passwordField {
  width: 100%;
  border: 1px solid #dfe8fc;
  box-sizing: border-box;
  border-radius: 5px;
}
.passwordTable {
  width: 100%;
}
.passwordTable td:last-child {
  padding: 0 0.4em;
}
.pwTable {
  width: 100%;
  height: 40px !important;
  background: #ffffff;
  /* Card border */

  border: 1px solid #dfe8fc;
  box-sizing: border-box;
  border-radius: 5px !important;
}
.passwordToggle {
  width: 1.05em;
  height: 0.9em;
}
.passwordToggleBtn {
  border: 0;
  background: none;
}

.loginCardBody > div {
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: center;
  justify-content: center;
  margin-top: 1em !important;
}
.login_card_form > div {
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: center;
  justify-content: center;
  margin-top: 1em !important;
}
td {
  padding: 0;
}
.passwordForm {
  height: 40px;
}

.continueBtn {
  background: $primary-blue;
  border-radius: 0.45em;
  height: 2.4em;

  width: 100%;
  margin-top: 1.3em;
}
.submitBtn {
  height: 2.4em;
  margin-top: 16px;
}

.btn {
  color: white;
  font-family: Metropolis;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  height: 2.4em;
  display: grid;
}
.btn:hover,
.btn:active,
.btn:focus {
  color: #8e919c;
  outline: none !important;

  box-shadow: none;
}
.cardFootTexts {
  color: $primary-blue;
  margin-top: 23px;
  cursor: pointer;
}
.label_gray {
  color: #8e919c !important;
}
.no_input_margin {
  margin-bottom: 1em;
  .huklGi {
    height: 38.4px;
  }
  input {
    margin-bottom: 0 !important;
  }
}
/*************************Signup one styles****************************/
.loginCardSignupOne {
  height: 630px;
}
.signupOne {
  margin-top: 1.8em;
  margin-bottom: 150px;
  width: 82%;
  margin-right: auto;
  margin-left: auto;
}
/*************************\Signup one styles****************************/

/*************************Signup two styles****************************/
.loginCardSignupTwo {
  height: 100%;
  margin-bottom: 5em;
}

.signupTwo {
  padding-top: 0;
  margin-bottom: 150px;
}
.loginCardSignupTwo > div > div > span > img {
  width: 1.1em;
  height: 1em;
}

/*************************\Signup two styles****************************/

/*************************Signup three styles****************************/

.signupTwo {
  padding-top: 0px;
  margin-bottom: 150px;
}

/*************************\Signup three styles****************************/

/*************************Hospital setup header styles****************************/

.hospital_container {
  width: 85%;
  margin-right: auto;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: 100px;
}
.SetupContainer {
  position: relative;
  padding: 0;
}
.HosSetupHeadImg {
  width: 1em;
  height: 1.05em;
}
.hosSetupHeader > span {
  width: 2.4em;
  height: 2.4em;
  background: #dfe8fc;
  border-radius: 0.7em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
.hosSetupHeader {
  align-items: flex-end;
  margin-top: 90px;
  margin-bottom: 30px;

  left: 0;
  top: 0;
}
.setupHeadText > h5,
.add_form_section_titles h6 {
  @include label_subheading(none, #8e919c);
  margin-bottom: 0;
}
/*************************\Hospital setup header styles****************************/

/*************************Hospital setupOne body styles****************************/
.hospitalSetUpOneBody {
  border: 1px solid #dfe8fc;
  box-sizing: border-box;
  box-shadow: 0px 8px 19px rgba(34, 84, 211, 0.15);
  border-radius: 20px;
}
.dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
  color: $primary-blue;
}
.dropdown-toggle-split:hover,
.dropdown-toggle-split:active,
.dropdown-toggle-split:focus,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus,
#dropdown-split-basic:hover,
#dropdown-split-basic:active,
#dropdown-split-basic:focus {
  background-color: none;
  background: none;
  outline: none;
}
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background: none;
  border: none;
  outline: none;
}
.dropdown {
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #dfe8fc;
  box-sizing: border-box;
  border-radius: 5px;
}
.dropDownInput {
  border: none;
}

.dropdown-toggle-split:hover,
.dropdown-toggle-split:active,
.dropdown-toggle-split:focus,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus,
#dropdown-split-basic:hover,
#dropdown-split-basic:active,
#dropdown-split-basic:focus {
  background-color: none;
  background: none;
  outline: none;
  outline-color: transparent;
  outline-width: 0;
}
.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background: none;
  border: none;
  box-shadow: none;
}
.show > .btn-primary.dropdown-toggle:focus,
.show > .btn-primary.dropdown-toggle:hover,
.show > .btn-primary.dropdown-toggle:active {
  box-shadow: none;
}
/*************************\Hospital setupOne body styles****************************/
/**********************Checkbox styles***************/
.globally_searchable_check-box {
  margin: 0.5em 0 1em;
}
.heHOgF {
  fill: none;
  stroke: white;
  strokewidth: 3px;
  padding-bottom: 10px;
}

._3vLmCG3bB3CM2hhAiQX1tN {
  position: absolute;
  width: 30%;
  background: #fff;
  border-radius: 4px;
  margin-top: 1px;
  z-index: 2;
  float: right;
  margin-left: 70%;
}
.check {
  margin-left: 10px;
}
.checkBox {
  background: #dfe8fc;
  border-radius: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 15px 10px;

  margin-top: 10px;

  width: 100%;
}
.checkBoxGroup {
  padding: 10px;
}
.checkBox > label {
  margin-bottom: 0;
  display: flex;
  flex-direction: row !important;
  white-space: nowrap;
  align-items: center;
}
.checkBoxGroup > div:last-child,
.checkBoxGroup > div:first-child {
  margin-bottom: 15px;
}
.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1.5em !important;
}
.checkBoxLastChild > .checkBox {
  margin-top: 0;
}
.checkBoxLabel {
  padding-left: 10px;
  margin-bottom: 0;
  /* Normal text */

  font-family: Metropolis;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;

  /* identical to box height */

  /* Blue */

  color: $primary-blue;
}
.acc__check__box__label {
  padding-left: 10px;
  margin-bottom: 0;
  /* Normal text */

  font-family: Metropolis;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;

  /* identical to box height */

  /* Blue */

  color: $primary-blue;
}
.active_cb {
  display: inline-block;
}
.all_active_cb {
  display: inline !important;
}
.all__checkbox {
  display: inline-block;
}

/**Auto complete styles**/
.igZibq {
  min-height: 2.4em !important;
}
.huklGi > .wrapper {
  border-radius: 0.24em !important;
  //  height: 2.4em !important;
  border: 1px solid #dfe8fc !important;
  z-index: 99 !important;
  box-shadow: none !important;
}
.huklGi > .wrapper:hover,
.huklGi > .wrapper:focus,
.huklGi > .wrapper:active {
  border-color: $primary-blue !important;
  box-shadow: none !important;
}

/********************Dropdown styles******************/
.all_single_select_adjust .css-1uccc91-singleValue {
  top: 60% !important;
}
.multi_dropdown {
  height: auto !important;
  margin-bottom: 10px;
}
.single_dropdown_wide .react-select__single-value {
  top: 60%;
}
.multi_dropdown .react-select-container,
.multi_dropdown .react-select__control {
  height: auto !important;
}
.react-select__menu {
  top: 100%;
  background-color: hsl(0, 0%, 100%);
  margin-bottom: 8px;
  position: absolute;
  width: 85% !important;
  z-index: 999 !important;
  box-sizing: border-box !important;
  margin-left: 7.5% !important;
  margin-top: -2% !important;
  background: #ffffff !important;
  border-radius: 10px !important;
}
.react-select-container {
  height: 2.4em;
  //height: fit-content;
}
.react-select__control {
  height: 100%;
}
.react-select__option {
  font-size: 0.7em !important;
}
.react-select__value-container {
  height: 100%;
}
/*Dropdown list container*/
.css-11unzgr {
  align-items: center;
  justify-content: center;
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 4px;
  padding-top: 4px;
  position: relative;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  transition: all 0.6s cubic-bezier(0.6, 1.5, 0.5, -0.5) !important;
}
.css-11unzgr > div {
  display: flex !important;
  flex-direction: column !important;

  width: 95% !important;
  margin-left: 2.5% !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.react-select__multi-value {
  background: $primary-blue !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.13);
  border-radius: 5px !important;
}
.css-bg1rzq-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border: 1px solid #dddddf;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;

  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}
/* dropdown list*/
.css-dpec0i-option {
  cursor: default;

  background: #dfe8fc !important;
  border-radius: 10px !important;
  padding: 8px 12px;
  font-family: Metropolis !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 14px !important;
  color: $primary-blue !important;
  width: 100% !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex !important;
  box-sizing: border-box;
}

.css-xo7z33-option > label,
.css-fk865s-option > label,
.css-dpec0i-option > label {
  width: 83px;
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.css-fk865s-option {
  display: flex !important;
}
.css-bg1rzq-control {
  border: 1px solid #dddddf;

  border-radius: 0.24em;
}

.css-12jo7m5 {
  /* Normal text */

  font-family: Metropolis;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 16px;
  /* identical to box height */

  color: #ffffff !important;
}
/***dropdownbtns*/
.css-es53b3-multiValue {
  background: $primary-blue !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.13) !important;
  border-radius: 5px !important;
  color: white !important;
}
/* dropdown clicked*/
.css-xo7z33-option {
  cursor: default;

  background: #dfe8fc !important;
  border-radius: 10px !important;
  padding: 8px 12px;
  font-family: Metropolis !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 14px !important;
  color: $primary-blue !important;
  width: 100% !important;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex !important;
  box-sizing: border-box;
}
/**Caratdown*/
svg:not(:root).svg-inline--fa {
  overflow: visible;
  color: $primary-blue !important;
}

input[type="file"]::-webkit-file-upload-button {
  display: none;
}

/**************File input Styles****************/
.hiddenFileInput {
  border: none;
  box-sizing: unset;
  background: none;
  display: none;
  width: 0%;
}
.fileInputContainer {
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #dfe8fc;
  padding: 0 20px;
  border-radius: 5px;

  overflow-x: hidden;
}
.file_input_value_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.fileInputContainer:active,
.fileInputContainer:focus,
.fileInputContainer:hover {
  border: 1px solid $primary-blue;
}
.selectedFileName {
  font-family: Metropolis;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  white-space: nowrap;
  /* identical to box height */

  /* Black */

  color: #071232;
}
.fileNameLabel {
  float: left !important;
  margin-bottom: 0;
  padding-left: 10px;
  width: 95%;
}

#uploadIcn {
  float: right !important;
  margin-right: 10px;
}

/* --------------------HospitalSetup Styles-------------------*/
.hospitalSetupContainer {
  height: 600px;

  margin-top: 10px;
  margin-bottom: 100px;
}
.hospitalTabBody {
  margin-top: 10px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  /* Card border */

  border: 1px solid #dfe8fc;
  box-sizing: border-box;
  box-shadow: 0px 8px 19px rgba(34, 84, 211, 0.15);
  border-radius: 20px;
}
.hospitalTabBody > div {
  width: 100%;
}
.accordion_services_container {
  display: grid;
  place-content: center;
  grid-gap: 0.5em;
  grid-template-columns: repeat(auto-fill, 49%);
}
.accordion__content__checkboxes {
  display: grid;
  place-content: center;
  grid-gap: 0.5em;
  grid-template-columns: repeat(auto-fill, 48%);
}
/******************************************************Setup tabs styles*****************************************************/

.tabs {
  background: #ffffff;
  /* Card border */

  border: 1px solid #dfe8fc;
  box-sizing: border-box;
  box-shadow: 0px 0.38em 0.9em rgba(34, 84, 211, 0.15);
  border-radius: 1em;
}
.tabsPane {
  border-bottom: 1px solid #e6e8ec;
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-direction: row;
  height: 4.3em;
}
.tabs .tab {
  margin-top: 30px;
  color: rgba(0, 0, 0, 0.6);
  display: inline-block;
  margin: 25px 2.1em 0 0;
  white-space: nowrap;
  cursor: pointer;

  @include normal_text(#8e919c, capitalize);
}
.tabs .tab:first-child {
  margin-left: 2.2em;
}
.button_text {
  @include btn_text();
}
/******************************************************Hos primary info Setup tabs styles*****************************************************/
.hos_setup_pri_info_container > form {
  display: flex;
  flex-direction: row;
}

.label_subheading_blue {
  @include label_subheading(none, $primary-blue);
}
.label_subheading {
  @include label_subheading(none, #8e919c);
}
.addressTag {
  @include label_subheading(none, #8e919c);
}
.hos_setup_pri_Info_right {
  width: 48%;
  margin-left: 2%;
}
.hos_setup_pri_info_container textarea {
  background: #ffffff;
  border: 1px solid #dfe8fc;
  border-radius: 0.24em;
  width: 100%;
  height: 7.5em;
  padding: 1em;
}
.hos_setup_pri_Info_left {
  width: 48%;
  margin-right: 2%;
}
.hos_setup_pri_Info_left > div {
  margin-bottom: 1em;
}
.active {
  background: none;
  border-bottom: 3px solid $primary-blue !important;
  transition: all 0.6s ease;
  color: $primary-blue !important;
}
.tabsContent {
  padding: 2em !important;
  padding-bottom: 200px;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(255, 255, 255, 0.2);
}

button.tab {
  background: none;
  border: none;
  width: auto;
}
button.tab:focus {
  outline: none;
}
.hos_setup_pri_services_container {
  width: auto;
  background: #f5f8fe;
  border-radius: 0.71em;
  padding: 0.9em 0.6em;
}
.hos_setup_pri_services_content {
  display: grid;
  place-items: center;
  grid-template-columns: repeat(auto-fill, 45%);
  grid-gap: 0.4em;
  width: 100%;
}
.submitBtn {
  background: $primary-blue;
  border-radius: 0.4em;
}
.hos_setup_table_head th {
  text-align: center;
}
/******************Testing tabs*****************/

/************Location Styles***************/

.location_form_container {
  width: 100%;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(auto-fill, 48%);
  place-content: center;
  border-top: 0.047em solid #e6e8ec;
}
._subgroup_container {
  width: 100%;
  display: grid;
  grid-gap: 0.8em;
  grid-template-columns: repeat(auto-fill, 47%);
  place-content: center;
}
._subgroup_container textarea,
.add_form_section .css-yk16xz-control {
  width: 100% !important;
}
.location_tab_container_top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1em 0;
}

.location_tab_container > div:last-child {
  width: 100%;
  padding: 20px;
}
.Secondary_title {
  font-family: SofiaProSemiBold;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9em;
  line-height: 24px;

  color: #000000;
}
.locationFormField {
  background: rgba(223, 232, 252, 0.3);
  border-radius: 15px;
  margin-top: 20.5px;
}
.locationFormField > div {
  padding: 25px;
}
.locationFormField > div > div {
  margin-top: 20px;
}

.locationTop {
  width: 50%;
  padding: 25px;
}
.locationTopCont {
  border-bottom: 1px solid #e6e8ec;
}
.locationFormFieldThree {
  margin-bottom: 20px;
}

.setup_days_table {
  background: #f5f8fe !important;
  margin-bottom: 1em;
}
.setup_days_table {
  width: 100% !important;
}
.setup_days_table table {
  width: 100% !important;
}
.setup_days_table td {
  padding: 0.8em 0em !important;
}
.finishBtn {
  margin: auto 2em auto auto;
  width: 9em;
}
.setup_working_days_table input {
  width: 90% !important;
}
.setup_day_head {
  text-align: left !important;
  padding-left: 3em !important;
}
/**************************Accordion styles********************/
.m_a_c_col_1 {
  width: 50%;
  margin-right: 5px;
  margin-left: 15px;
}
.m_a_c_col_2 {
  width: 50%;
  margin-left: 5px;
  margin-right: 15px;
}
.m_a_c_row {
  margin-bottom: 15px;
}
/************************Drugs Availability styles*************************/
.add_drug_btn {
  background: #dfe8fc;
  border-radius: 10px;
  border: none;
  outline: none;
  height: 30px;

  font-family: Metropolis;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height */

  /* Blue */

  color: $primary-blue;
  width: 35%;
  margin-top: 12px;
}
.add_drug_btn > img {
  margin-right: 7px;
}
.arv_input {
  height: 50px;
  background: #ffffff;
  /* Blue */

  border: 1px solid $primary-blue;
  box-sizing: border-box;
  border-radius: 5px;
}
.p_a_c_col_1 {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.create-container {
  padding-top: 90px;
}
.arv_tag {
  /* Label & subheading */

  font-family: Metropolis;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height */

  /* Blue */

  color: $primary-blue;
  margin-bottom: 14px;
}
/*********************DashBoard Styles ***********************/
.db_container {
  padding: 0;
  height: 100%;
}
.db_home_wrapper {
  width: auto;
  height: auto;
  min-height: 100vh;
  background: #ffffff;
  /* Card border */
  position: relative;
  right: 0;
  border: 1px solid #dfe8fc;
  box-sizing: border-box;
  box-shadow: 0px 8px 19px rgba(34, 84, 211, 0.15) !important;
  margin-left: 180px;
  padding-bottom: 20%;
  z-index: 99;
}
.db_content {
  width: 83%;
  margin-left: auto;
  margin-right: auto;
}
.dbHead {
  height: 4.8em;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e8ec;
}
.db_head_content {
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.db_head_text {
  font-family: Metropolis;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 0.76em;
  line-height: 16px;
  /* identical to box height */

  letter-spacing: 0.1em;
  color: #8e919c;
  text-transform: uppercase;
  margin-bottom: 0;
}
.db_head_text > span {
  color: $primary-blue !important;
  font-weight: bolder !important;
}
.db_head_time {
  font-family: Metropolis;
  font-style: normal;
  font-weight: 500;
  font-size: 0.76em;
  line-height: 16px;
  /* identical to box height */

  letter-spacing: 0.1em;

  /* Blue */

  color: $primary-blue;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}
.db_head_avatar {
  width: 2.3em;
  height: 2.3em;
  margin-left: auto;
  margin-right: 10px;
}
.db_bell {
  width: 17px !important;
  height: 18px !important;
}
.db_bell_tag {
  width: 0.45em;
  height: 0.45em;
  margin-left: -0.4em;
  margin-top: -0.8em;
}
.head_images {
  display: flex;
  align-items: center;
}
.notification_span {
  background: #dfe8fc;
  border-radius: 50%;
  width: 2.3em;
  height: 2.3em;
  display: flex;
  align-items: center;
  justify-content: center;
}
/*********** DB Modal*********/

.dbModal {
  position: relative;
  background: $primary-blue;
  border-radius: 1em;
  margin-top: 2em;
  margin-bottom: 2em;
  height: 9.6em;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 30px;
}

.modal_content {
  display: flex;
  height: auto;
  width: 100%;

  margin-top: auto;
  margin-bottom: auto;
}
.all_db_modal_texts {
  margin-top: -17%;
  margin-left: 35px;
  width: 65%;
}
.modal_title {
  font-family: Metropolis;
  font-style: normal;
  font-weight: bold;
  font-size: 1.1em;
  line-height: 24px;
  /* identical to box height */

  color: #ffffff;
}
.modal_body {
  /* Normal text */

  font-family: Metropolis;
  font-style: normal;
  font-weight: 500;
  font-size: 0.76em;
  line-height: 16px;

  /* Card border */

  color: #dfe8fc;
  padding: 0 3px;
  margin-bottom: 0.7em;
}
.modal_btn {
  outline: none;
  border: none;
  background: #22d389;
  border-radius: 0.4em;
  width: 9.1em;
  height: 2.4em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal_btn_text {
  /* Btn text */

  font-family: Metropolis;
  font-style: normal;
  font-weight: 800;
  font-size: 0.8em;
  line-height: 16px;
  /* identical to box height */

  color: #ffffff;
  margin-bottom: 0;
}
.modal_background {
  height: auto;
  width: 100%;
  margin-top: -1em;
}
/*****************************************DB status*****************************/
.dbStatus {
  background: rgba(223, 232, 252, 0.3);
  border-radius: 0.6em;

  height: 6.1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.8em;
}
.status_content {
  margin-top: auto;
  margin-bottom: auto;
  height: auto;
  width: 80%;
}
.status_bl {
  text-align: center;
  line-height: 5px;
}
.status_bl > span:before,
.status_bl > span:after {
  content: "";
  position: absolute;
  height: 8px;
  border-bottom: 1px solid #dddddf;
  top: 70;
  width: 7em;
}
.status_bl > span:before {
  right: 100%;
  margin-right: 10px;
}
.status_bl > span:after {
  left: 100%;
  margin-left: 10px;
}
.status_text {
  /* Normal text */

  font-family: Metropolis;
  font-style: normal;
  font-weight: 500;
  font-size: 0.76em;
  line-height: 16px;
  /* identical to box height */

  /* Label & subheading */

  color: #8e919c;

  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  background: solid rgba(223, 232, 252, 0.3);
  z-index: 99;
  display: inline-block;
  position: relative;
}
/***********************************DB Add Doc******************************/
.add_doc_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 4.6em;
}
.db_back_arrow {
  width: 0.8em;
  height: 0.8em;
  margin-bottom: 0.15em;
}
.back_arrow_btn {
  background: #dfe8fc;
  border-radius: 50%;
  width: 2.3em;
  height: 2.3em;
  border: none;
  outline: none;
}
.db_doc_head_text {
  font-family: Metropolis;
  font-style: normal;
  font-weight: 500;
  font-size: 0.76em;
  line-height: 16px;
  text-transform: uppercase;
  margin-right: 0.5em;
  margin-left: 20px;
  margin-bottom: 0;
  /* identical to box height */

  letter-spacing: 0.1em;

  /* Black */

  color: #071232;
}
.db_user_name {
  margin-right: auto;
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 0.76em;
}
.add_doc_btn {
  border: none;
  width: 14.6em;
  height: 2.4em;
  border-radius: 0.4em;
  margin-top: 10.4px;
}
.add_doc_btn_text {
  /* Btn text */

  font-family: Metropolis;
  font-style: normal;
  font-weight: 800;
  font-size: 0.76em;
  line-height: 16px;
  /* identical to box height */

  color: #ffffff;
  margin-bottom: 0;
}
.add_doc_body_text {
  font-family: Metropolis;
  font-style: normal;
  font-weight: normal;
  font-size: 0.86em;
  line-height: 18px;
  /* identical to box height */

  /* Black */

  color: #071232;
}
.add_via_email_container {
  margin-top: 2em;
}
.add_doc_via_email_top_left {
  width: 75%;
}
.add_doc_via_email_top {
  width: 100%;
}
.add_doc_via_email_top_right {
  width: 25%;
}
.add_doc_via_email_bottom {
  width: 74%;
  height: 100%;
  margin-top: 0.1em;
  margin-right: auto;
  /* bounding box */

  background: rgba(223, 232, 252, 0.3);
  border-radius: 15px;
  padding: 1.5em;
}
.add_doc_via_email_bottom_content {
  padding: 0.5em 20px 0;
}
.add_doc_via_email_top_left > h3 {
  font-family: Metropolis-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 24px;
  color: #071232;
  margin-bottom: 0;
}
.add_doc_via_email_top_left > p {
  font-family: Metropolis;
  font-style: normal;
  font-weight: 500;
  font-size: 0.76em;
  line-height: 24px;
  color: #8e919c;
  margin-bottom: 0;
}
.email_invite_form {
  margin-bottom: 0.7em;
}
.email_invite_form .label_subheading_blue {
  color: rgb(39, 177, 219) !important;
  font-size: 0.9em;
  height: 1em;
}
.email_invite_inputs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5em;
}
.email_invite_inputs .db_input_container {
  width: 48%;
}
.email_invite_form .add_button {
  margin: 0 0 0 auto;
}
.db_input_container {
  flex-direction: column !important;
}
.db_input {
  height: 2.4em;
  background: #ffffff;
  /* Blue */
  border: 1px solid #dfe8fc;
  box-sizing: border-box;
  border-radius: 0.2em;
  color: #071232;
  font-family: SofiaProRegular;
  font-weight: normal;
}

.db_input::placeholder {
  font-size: 1em;
  text-justify: auto;
  padding-bottom: 1em;
}
.db_input:active,
.db_input:focus {
  border: 1px solid $primary-blue;
}

.db_input_fields > h6 {
  /* Label & subheading */
  margin: 1em 0 0.5em 0;
  font-family: Metropolis-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 0.66em;
  line-height: 14px;
  /* identical to box height */

  /* Blue */

  color: $primary-blue;
}
.add_input_btn {
  margin-top: 10px;
  background: #dfe8fc;
  border-radius: 1em;
  border: 0;
  height: 2.1em;
  width: 9em;

  font-family: Metropolis-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 0.76em;
  line-height: 16px;
  /* identical to box height */

  /* Blue */

  color: $primary-blue;
}
.add_input_btn > img {
  margin-right: 0.4em;
  width: 1.1em;
  height: 1.1em;
}
.send_invite_btn {
  border: 0;
  background: $primary-blue;
  border-radius: 10px;
  height: 2.4em;
  margin-top: 35px;
}
.send_invite_btn > h6 {
  /* Btn text */

  font-family: Metropolis;
  font-style: normal;
  font-weight: 800;
  font-size: 0.76em;
  line-height: 16px;
  margin-bottom: 0;
  /* identical to box height */

  color: #ffffff;
}
/**************************************DB Side_Nav Styles******************/
.db_side_nav {
  position: fixed;
  overflow-y: scroll;
  scrollbar-width: none;
  width: 180px;
  background-color: #f5f7fa;
  height: 100% !important;
  top: 0;
  left: 0;
  margin-right: auto;
  z-index: 1;
}

.sidenav {
  display: none;
}
// .db_side_nav::-webkit-scrollbar {
//   height: 15px !important;
//   border-radius: 20px;
//   width: 0px;
//   background-color: #dfe8fc;
// }
.db_menu_item {
  margin-bottom: 0.3em;
}
.db_menu_item > a button h6 {
  font-family: SofiaProMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 0.78em;
  line-height: 14px;
  /* identical to box height */

  /* Label & subheading */

  color: #8e919c;
  margin-top: 3px;
}
.navbar_link_active h6 {
  color: $primary-blue !important;
}
.db_menu_item > a button svg {
  fill: #8e919c;
  margin-right: 0.75em;
  margin-top: 0.1em;
}
.navbar_link_active button svg {
  fill: $primary-blue !important;
}
.db_menu_item > a button {
  display: flex;
  align-items: center;

  width: 75%;
  margin-left: 1.7em;
  margin-right: auto;
  height: 2.4em;
  border: none;
  background: transparent;
}
.db_menu_item nav {
  display: flex;
  flex-direction: row;
  width: 100px;
  margin-right: auto;
  margin-left: auto;
}
.db_menu_item .navbar_link_active button {
  background-color: #dfe8fc;
  border-radius: 0.45em;
  outline: none;
  color: $primary-blue;
  transition: background-color 0.6s ease-in !important;
}

.db_menu_item > a button > span {
  display: flex;
  align-items: center;
}
.logoutitem {
  margin-top: 5em;
}
.logoutitem h6 {
  color: #f01919 !important;
}
.DBbackgroundtop {
  width: 100%;
  height: 8.5em;
  margin-bottom: 1em;
}
.DBbackground_down {
  width: 180px;
  height: 100px;
  bottom: 0;
  position: relative;
}
.hamburger_btn {
  height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-right: 15px;

  margin-bottom: 1.1em;
}

.ham_crossed > div:nth-child(1) {
  transform: rotate(45deg);
}
.ham_crossed > div:nth-child(2) {
  opacity: 0;
}
.ham_crossed > div:nth-child(3) {
  transform: rotate(-45deg);
}

.hamburger_btn_alt {
  margin-right: 0px;

  margin-bottom: 0.5em;
}
.hamburger_btn:focus {
  outline: none;
}
.hamburger {
  width: 25px;
  height: 2px;
  background-color: $primary-blue;
  transition: all 0.15s linear;
  transform-origin: 1px;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 24, 57, 0.35);
  z-index: 999;
}

/**************************************Goto btns***************************/
.goto_link_btn {
  border: none;
  color: white;
  background-color: #071232;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 15px;
}
.settings_link {
  position: absolute;
  top: 0;
}
.dashboard_link {
  position: absolute;
  top: 0;
  right: 0;
}
.settings_goto_link_btn {
  border: 0;
  color: white;
  background: #071232;
  margin-top: 5px;
  margin-left: 5px;
  border-radius: 5px;
  padding: 3px;
}
/************************************Dashboard Main Home styles************************************/
.db_main_home_top_container {
  display: flex;
  border-bottom: 0.047em solid #f3f4f8;
  padding-bottom: 1.5em;
}
.main_home_rate_card {
  background: #f3f4f8 !important;
}
.db_main_home_top_accordion {
  width: 7em;
  margin: 1.5em 0 1em;
}
.db_main_home_top_accordion .accordion {
  height: 2em;
}
.db_main_home_rate-cards {
  width: 50%;
  display: grid;
  grid-gap: 0.7em;
  grid-template-columns: repeat(auto-fill, 11.5em);
  place-content: flex-start;
  margin-right: 0.5em;
  place-self: self-start;
}
.db_main_home_rate_cards_bottom {
  width: 100%;
  display: grid;
  grid-gap: 0.7em;
  grid-template-columns: repeat(auto-fill, 11.5em);
  place-content: flex-start;
}

.db_main_home_pat_break_down {
  background: #f5f8fe;
  border-radius: 0.71em;
  width: 48%;
  display: flex;
  flex-direction: row;
  height: fit-content;
  padding: 1.8em 2em 3.5em 1.4em;
}
.db_main_home_pat_break_down h5 {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 22px;
  /* identical to box height */

  /* Black */

  color: #071232;
}
.out_patient_btn {
  background: #ff8159;
  border: 0.24em solid #f5f8fe;
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: 0.2em 0.2em 0.71em rgba(255, 129, 89, 0.21);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -1em 0 0;
}
.out_patient_btn h6 {
  margin-bottom: 0;
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: 500;

  line-height: 43px;
  color: #ffffff;
}
.in_patient_btn {
  background: #874fff;
  border: 0.24em solid #f5f8fe;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -3em 3.5em;
}
.in_patient_btn h6 {
  margin-bottom: 0;
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: 500;

  line-height: 22px;
  color: #ffffff;
}
.db_main_home_legends > div {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
}
.db_main_home_legends > div:first-child {
  margin-bottom: 0.9em;
}
.db_main_home_legends > div h6 {
  margin-bottom: 0;
  font-family: SofiaProregular;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8em;
  line-height: 1em;
  /* identical to box height */

  /* Label & subheading */

  color: #8e919c;
}
.out_patient_legend,
.in_patient_legend {
  width: 0.8em;
  height: 0.8em;
  background-color: #f5f8fe;
  border-radius: 50%;
  box-sizing: border-box;
  margin-right: 0.4em;
}
.out_patient_legend {
  border: 0.24em solid #ff8159;
}
.in_patient_legend {
  border: 0.24em solid #874fff;
  margin-left: -0.3em;
}
.db_home_rate_cards_bottom_container {
  margin-top: 1.5em;
}
.db_home_rate_cards_bottom_container > h6:first-child {
  font-family: SofiaProMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 0.76em;
  line-height: 1em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $primary-blue;
  margin-bottom: 1em;
}
.emergency_not_icon {
  width: 2.9em;
  height: 2.9em;
  border-radius: 50%;
  background: #ff6f4f;
  margin: -1.45em 0 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.emergency_not_icon svg {
  margin-right: -0.6em;
}
.emergency_not_count {
  width: 1.3em;
  height: 1.3em;
  border-radius: 50%;
  background-color: #071232;
  margin-top: -2.2em;
  margin-right: -0.5em;
}
.emergency_not_count > h6 {
  font-size: 0.9em;
  color: #ffffff;
}
.emergency_not_count_container {
  width: 100%;
  display: flex;
  justify-items: flex-end;
}
/************************************Dashboard Notifications Home styles************************************/
.db_notifications_container {
  z-index: 99999999;
  position: absolute;
  background: #f5f7fa !important;
  width: 93%;
  height: 100%;
  margin: 0 0 0 -8.5%;
  top: 0;
}
.not_close_icon {
  position: absolute;
  top: 1.5em;
  right: 8.5%;
  z-index: 9;
}
.request_notify_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.8em 1.4em;
  border-bottom: 0.047em solid #f3f4f8;
  border-top: 0.047em solid #f3f4f8;
}
.request_notify_container > div:first-child {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.request_notify_container > div:first-child h6:first-child {
  font-family: SofiaProMedium;
  font-style: normal;
  font-weight: 600;
  font-size: 0.86em;
  line-height: 1.2em;
  /* identical to box height */

  /* Black */

  color: #071232;
}
.request_notify_container > div:first-child h6:last-child {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 0.57em;
  line-height: 16px;

  /* Blue */

  color: $primary-blue;
  margin-bottom: 0;
}
.request_notify_container_count_text,
.request_notify_count_text {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 0.71em;
  line-height: 0.95em;

  /* Label & subheading */

  color: #8e919c;
}
.request_notify_container_view,
.request_notify_container_accept {
  width: 3.8em;
  height: 1.2em;
  border-radius: 4.76em;
  margin-bottom: 0.4em;
}
.request_notify_container_view {
  background-color: #dfe8fc;
  margin-right: 0.4em;
}
.request_notify_container_accept {
  background-color: rgba(34, 211, 137, 0.15);
}
.request_notify_container_view h6,
.request_notify_container_accept h6 {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 0.57em !important;
  line-height: 0.8em;
  text-transform: uppercase;
  margin-bottom: 0;
}
.request_notify_container_view h6 {
  color: $primary-blue;
}
.request_notify_container_accept h6 {
  color: #22d389 !important;
}
.home_all_req_container > h6:first-child {
  margin-bottom: 2.5em;
}
.home_all_req_top_title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2em;
}
.home_all_req_top_title > h6 {
  font-family: SofiaProMedium;
  font-style: normal;
  font-weight: 600;
  font-size: 0.86em;
  line-height: 1.2em;
  /* identical to box height */

  /* Black */

  color: #071232;
}
.home_all_req_top_title > button {
  background: $primary-blue;
  border-radius: 4.76em;
  width: 4.75em;
  height: 1.19em;
}
.home_all_req_top_title > button > h6 {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 0.57em;
  line-height: 0.8em;
  text-transform: uppercase;

  /* White */

  color: #ffffff;
  margin-bottom: 0;
}
.request_notify_count_text {
  margin-bottom: 2.5em;
}
/************************************Dashboard Notifications Tabsstyles************************************/
.active_not_tab {
  background: #fffcf5 !important;
}
.notification_tab {
  width: 15em;
  position: fixed;
  background: #ffffff;
  left: 180px;
  top: 0;
  display: flex;
  flex-direction: column;
  padding: 3em 0 0 0;
  overflow-y: scroll;
  cursor: pointer;
  height: 100%;
}
.notification_tab_accordion {
  padding: 0 1.4em;
}
.notification_tab_content {
  background: #f5f7fa;
  position: absolute;
  top: 0;
  left: 15em;
  width: 75%;
  height: 100%;
  padding: 1.5em 5em 0 1.4em;
}
/************************************Dashboard Emergency Home styles************************************/
.emergency_service_btn {
  height: 2.4em;
  background: #071232;
  border-radius: 0.41em;
  width: 13.7em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.emergency_service_btn h6 {
  margin-right: 0.5em;
}
.ambulance_req_head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 1.2em 0;
}
.emergency_table_container {
  padding: 2em;
}
.accept_amb_btn,
.decline_amb_btn,
.ambulance_accept_btn,
.ambulance_reject_btn {
  width: 4.47em;
  height: 1.4em;
  border-radius: 0.24em;
}
.accept_amb_btn {
  background-color: #22d389;
}
.decline_amb_btn {
  background-color: #ff6f4f;
}
.ambulance_accept_btn {
  background-color: rgba(34, 211, 137, 0.15);
}
.ambulance_reject_btn {
  background-color: rgba(255, 111, 79, 0.15);
}
.accept_amb_btn > h6,
.decline_amb_btn > h6,
.ambulance_accept_btn > h6,
.ambulance_reject_btn > h6 {
  font-family: SofiaProRegular;
  font-size: 0.57em !important;
  line-height: 0.8em !important;

  /* Green */
}
.accept_amb_btn > h6 {
  color: #ffffff !important;
}
.decline_amb_btn > h6 {
  color: #ffffff !important;
}
.ambulance_accept_btn > h6 {
  text-transform: uppercase;
  color: #22d389 !important;
}
.ambulance_reject_btn > h6 {
  text-transform: uppercase;
  color: #ff6f4f !important;
}
/************************************Dashboard doctors styles************************************/
.DB_doctors_top {
  display: flex;
  flex-direction: row;
  margin: 2em 0 -1em 0;
  height: 5.4em;
}
.DB_doctors_top .all_drop_drown_container {
  width: 8em !important;
  margin-right: 0.7em;
}

.DB_doctors_top .all_drop_down_top {
  display: none;
}
.DB_doctors_top .react-select__control {
  background: #071232;
  border-radius: 0.4em;
  width: 8em !important;
}
.DB_doctors_top .react-select__control--is-focused {
  border: none !important;
  outline: none !important;
}
.DB_doctors_top .react-select__indicator {
  background: rgba(223, 232, 252, 0.21);
  border-radius: 0.4em;
  height: 1.7em;
  width: 1.7em;
  margin-right: 0.3em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.DB_doctors_top .react-select__placeholder,
.DB_doctors_top .react-select__single-value,
.DB_doctors_top .react-select__input input {
  font-family: SofiaProMedium;
  color: rgb(255, 255, 255) !important;
  font-size: 0.76em;
  line-height: 16px;
  font-weight: normal;
  top: 50%;
}
.DB_doctors_top .react-select__input input {
  display: none !important;
}
.doc_cards_search_bar {
  width: 100%;
}
.doctor_card_container {
  display: grid;
  display: -moz-grid;
  place-content: flex-start;
  grid-gap: 1em;
  grid-template-columns: repeat(auto-fill, 11em);
  margin-top: 1em;
}
.doctor_card {
  height: 13em;
  background: rgba(223, 232, 252, 0.3);
  border-radius: 15px;
  position: relative;
  display: grid;
  place-content: center;

  z-index: 99;
}
.patient_status_drop .react-select__control {
  background-color: #ff6f4f;
}
.doctor_card > a {
  display: grid;
  place-items: center;
}
.doctor_card > a > div {
  display: grid;
  place-items: center;
}

.doctor_card h5 {
  /* Normal text */
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  text-transform: capitalize;
  caption-side: bottom;
  display: block;
  font-family: SofiaProMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 0.76em;
  line-height: normal;
  text-align: center;
  /* identical to box height */

  /* Blue */

  color: $primary-blue;
}
.doctor_card_chevron_container {
  width: 11.3em;
  height: 2.5em;
  margin-top: -2em;
}
.doctor_card_chevron_btn {
  border-radius: 50%;
  width: 1.2em !important;
  height: 1.2em !important;
  background-color: #dfe8fc !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.2em 1em 0 auto;
}

.doctor_card > div:first-child {
  display: grid;
  place-items: center;
}
.doctor_card_btn {
  background: #ff6f4f;
  border-radius: 0.4em;
  width: 7.5em;
}

.doctor_card_btn > h6 {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 0.76em;
  line-height: 22px;
  /* identical to box height */
  text-align: center;
  margin: 0;
  padding: 0.4em 0.6em;
  color: #ffffff;
}
.consulting_card {
  width: 240px;
  height: 306px;
  color: $primary-blue;
  border-radius: 20px;
  color: #245de8;
}
/* Plan card styles */
.plan_book_container {
  max-height: 14em;
  position: relative;
}
.plan_card {
  position: relative;
}
.plan_card_leaves {
  position: absolute;
  top: -12.8em;
  left: 0.2em;
  height: 13em;
  //background-color: #071232;
  background: rgba(223, 232, 252, 0.3);
  border-radius: 15px;
  position: relative;
  display: grid;
  place-content: center;
  cursor: pointer;
  z-index: 9;
  box-shadow: 0px 8px 19px rgba(34, 84, 211, 0.15);
}

.plan_card .card_ribbon_container {
  position: absolute;
  top: 0;
  right: -0.1em;
  display: flex !important;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}

.plan_card .card_ribbon_container .card_ribbon {
  margin-bottom: 0em;
  transform: rotate(90deg);
}
.plan_card .card_pin_container {
  position: absolute;
  top: 35%;
  left: -0.1em;
  display: flex !important;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  height: 30%;
}
.plan_card .card_pin_container .card_ribbon {
  margin: auto;
}
.plan_card big {
  font-size: 2em;
  text-transform: uppercase;
  font-weight: bold;
}
.doctor_card_container .plan_card .doctor_card_main_content big:nth-child(1n) {
  color: #071232;
}
.doctor_card_container .plan_card .doctor_card_main_content big:nth-child(2n) {
  color: #ff5c00;
}
.doctor_card_container .plan_card .doctor_card_main_content big:nth-child(3n) {
  color: #3d7543;
}

.spec_content > ul {
  background: white;
  list-style-type: none;
  border-radius: 10px;
  margin-top: 0.1em;
  padding: 0.5em;
}
.spec_content > ul li {
  font-family: SofiaProMedium;
  font-style: normal;
  font-weight: normal;
  font-size: 0.76em;
  line-height: 21px;

  color: black;
}
.specdrop_container {
  margin-top: -0.3em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.spec_drop {
  border: none;
  background: #dfe8fc;
  width: auto;
  padding: 0.1em 0.6em;
  border-radius: 1em;
}
.spec_drop > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.spec_drop > div:first-child {
  font-family: SofiaProMedium;
  font-style: normal;
  font-weight: normal;
  font-size: 0.76em;
  line-height: 21px;

  /* Blue */

  color: $primary-blue;
}

.spec_rotate {
  width: 0.4em;
  height: 0.25em;
}
.spec_rotate_active {
  transform: rotate(180deg) !important;
}
.spec_chev {
  background: $primary-blue;
  height: 1.2em;
  width: 1.2em;
  border-radius: 100%;
  display: grid;
  place-items: center;
  margin-left: 0.7em;
}
.doctor_card_spec {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.doctor_card_spec_list {
  width: fit-content;
  height: fit-content;
  padding: 0.3em 0.6em;
  border-radius: 1em;
  background-color: #dfe8fc;
  margin-bottom: 0.4em;
}
.doctor_card_spec_list h6 {
  margin: 0;
  /* Normal text 2 */

  font-family: SofiaProregular;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8em;
  line-height: 1em;

  /* Blue */

  color: $primary-blue;
  white-space: nowrap;
}
.doctor_card_main_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.doctor_card_options_container {
  position: absolute;
  width: 6.5em;
  height: 5em;
  right: 0.9em;
  top: 2.6em;
  padding: 0.4em 1em 0.6em 0em;
  border-radius: 0.4em;
  background-color: #ffffff;
  border: 1px solid #dfe8fc;
}
.doctor_card_options_container button {
  background: none;
}
.doctor_card_options_container h6 {
  @include normal_text(#071232, capitalize);
  transition: transform 0.3s ease-out;
}
.doctor_card_options_container h6:hover,
.doctor_card_options_container h6:focus,
.doctor_card_options_container h6:active {
  transform: scale(1.1);
}
.consult_button {
  background-color: #dfe8fc;
  border-radius: 1.4em;
  outline: none;
  color: #2254d3;
}
/************************************Dashboard AddButton Styles************************************/
.add_button {
  height: 2.4em;
  width: 12em;
  margin-right: 0.7em;
  border-radius: 0.4em;
  background: #22d389;
  display: flex;
  align-items: center;
}
.add_button .add_button_plus_span {
  height: 1.5em;
  width: 1.5em;
  padding: 0.3em;
  border-radius: 0.4em;
  background: #18bd78;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.3em 0 0.6em;
}
.add_button h6 {
  margin: 0;
  font-family: SofiaProMedium;
  font-style: normal;
  font-weight: normal;
  font-size: 0.76em;

  /* identical to box height */
  white-space: nowrap;
  color: #ffffff;
  margin-right: 5px;
}
/*************************************DB doctor profile styles ********************************************/

.db_doc_profile_container {
  display: flex;
  flex-direction: row;
  margin-top: 2.2em;
}
.db_doc_profile_control {
  width: 14.9em;
  margin-right: 1em;
}
.db_doc_profile_control > .doctor_card {
  height: auto;
  padding: 0.5em;
}
.doc_control_btn {
  height: 2.4em;
  border-radius: 0.4em;
  margin-top: 0.45em;
}
.db_doc_profile_details {
  width: 100%;
}
.db_doc_profile_tab {
  display: flex;
  flex-direction: row;
  background: #dfe8fc;
  border-radius: 0.4em;
  height: 2.4em;
  align-items: center;
}
.db_doc_profile_tab button {
  width: auto;
  height: 1.9em;
  border-radius: 0.4em;
  background: none;
  margin: 0 0.2em 0 0.3em;
  padding: 0 0.6em;
  line-height: 22px;
  /* identical to box height */
}
.db_doc_profile_tab button > h6 {
  font-family: SofiaProRegular;
  font-size: 0.76em;
  font-style: normal;
  margin: 0;
}
.active_doc_profile_button {
  background: $primary-blue !important;
  /* Basic shadow */

  box-shadow: 0px 8px 19px rgba(34, 84, 211, 0.15);
}
.active_doc_profile_button > h6 {
  color: #ffffff;
}
.db_doc_profile_table_container {
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-top: 0.7em;
  border-radius: 0.7em;
  width: 100%;
}
.db_doc_profile_table {
  margin-right: 0.9em;
}
.db_doc_profile_table .tab {
  padding: 0.3em 0;
}
.db_doc_profile_table .tab h3:first-child {
  margin-bottom: 0.2em;
}
.db_doc_profile_table .tab h3:last-child {
  margin-bottom: 0.2em;
}
.db_doc_profile_table_container_two {
  display: flex;
  flex-direction: row;
  padding: 1em;
  width: 100%;
}
.db_doc_profile_table_cell_one > button {
  display: flex;
  flex: row;
  width: 11.9em;
  background: none;
  border-radius: 0.4em;
  margin-bottom: 1em;
  padding: 0.5em 0;
}
.db_doc_profile_table_day {
  width: 3.3em;
  border-right: 0.046em solid rgba(142, 145, 156, 0.24);
  margin-right: 0.5em;
}
.db_doc_profile_table_day > h3:first-child {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 0.76em;
  line-height: auto;
  /* identical to box height, or 125% */
  text-align: center;
  /* Black */

  color: #071232;
}
.db_doc_profile_table_day > h3:last-child {
  font-family: SofiaProBold;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6em;
  line-height: auto;
  /* identical to box height */

  text-align: center;

  /* Blue */

  color: black;
}
.active_schedule_tab .db_doc_profile_table_day > h3:last-child {
  color: $primary-blue !important;
}
.db_doc_profile_table_session {
  width: 8.5em;
  margin-bottom: 0.3em;
}
.db_doc_profile_table_session h3:first-child {
  font-family: SofiaProSemiBold;
  font-style: normal;
  font-weight: bold;
  font-size: 0.76em;
  line-height: 20px;
  text-align: left;
}
.active_schedule_tab .db_doc_profile_table_session h3:first-child {
  color: $primary-blue;
}

.DB_doc_schedule_card_container {
  width: 100%;
}
.db_doc_profile_table_session h3:last-child {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 0.76em;
  line-height: 20px;
  text-align: left;
}
.DB_doc_schedule_card {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;

  border-radius: 0.4em;
  margin-bottom: 0.5em;
  padding: 1em;
}
.DB_doc_schedule_card > div {
  display: flex;
  flex-direction: row;

  align-items: center;
  margin-bottom: 0.5em;
}
.DB_doc_schedule_card_container .DB_doc_schedule_card:nth-child(1n) {
  background: #eeeaff;
}
.DB_doc_schedule_card_container .DB_doc_schedule_card:nth-child(2n) {
  background: #ffebd9;
}
.DB_doc_schedule_card_container .DB_doc_schedule_card:nth-child(3n) {
  background: #b9ffc0;
}
.DB_doc_schedule_card_container .DB_doc_schedule_card:nth-child(1n) h5 {
  font-family: Metropolis-Medium;
  font-style: normal;
  font-weight: bolder;
  font-size: 0.76em;
  line-height: 16px;
  /* identical to box height */

  color: #5843be;
  margin-right: auto;
  margin-bottom: 0;
}
.DB_doc_schedule_card_container .DB_doc_schedule_card:nth-child(2n) h5 {
  font-family: Metropolis-Medium;
  font-style: normal;
  font-weight: bolder;
  font-size: 0.76em;
  line-height: 16px;
  /* identical to box height */

  color: #ff7a00;
  margin-right: auto;
  margin-bottom: 0;
}
.DB_doc_schedule_card_container .DB_doc_schedule_card:nth-child(3n) h5 {
  font-family: Metropolis-Medium;
  font-style: normal;
  font-weight: bolder;
  font-size: 0.76em;
  line-height: 16px;
  /* identical to box height */

  color: #3d7543;
  margin-right: auto;
  margin-bottom: 0;
}
.DB_doc_schedule_card_container .DB_doc_schedule_card:nth-child(1n) span {
  background: #ddd6ff;
  width: auto;
  height: auto;
  display: grid;
  place-items: center;
  padding: 0.6em 0.5em;
  border-radius: 0.4em;
  margin-right: auto;
}

.DB_doc_schedule_card_container
  .DB_doc_schedule_card:nth-child(1n)
  .db_schedule_pane_detail_edit_button {
  background-color: #ddd6ff;
}
.DB_doc_schedule_card_container
  .DB_doc_schedule_card:nth-child(1n)
  .db_schedule_pane_detail_edit_button
  svg {
  fill: #5843be;
}
.DB_doc_schedule_card_container .DB_doc_schedule_card:nth-child(2n) span {
  background: #ffd6af;
  width: auto;
  height: auto;
  display: grid;
  place-items: center;
  padding: 0.6em 0.5em;
  border-radius: 0.4em;
  margin-right: auto;
}

.DB_doc_schedule_card_container
  .DB_doc_schedule_card:nth-child(2n)
  .db_schedule_pane_detail_edit_button {
  background-color: #ffd6af;
}

.DB_doc_schedule_card_container
  .DB_doc_schedule_card:nth-child(2n)
  .db_schedule_pane_detail_edit_button
  svg {
  fill: #ff5c00;
}
.DB_doc_schedule_card_container .DB_doc_schedule_card:nth-child(3n) span {
  background: #8eff9a;
  width: auto;
  height: auto;
  display: grid;
  place-items: center;
  padding: 0.6em 0.5em;
  border-radius: 0.4em;
  margin-right: auto;
}

.DB_doc_schedule_card_container
  .DB_doc_schedule_card:nth-child(3n)
  .db_schedule_pane_detail_edit_button {
  background-color: #b2fea9;
}
.DB_doc_schedule_card_container
  .DB_doc_schedule_card:nth-child(3n)
  .db_schedule_pane_detail_edit_button
  svg {
  fill: #3d7543;
}

.DB_doc_schedule_card_container .DB_doc_schedule_card:nth-child(1n) span > h6 {
  font-family: Metropolis;
  font-style: normal;
  font-weight: normal;
  font-size: 0.66em;
  padding-top: 0.3em;
  line-height: 0.66em;

  /* identical to box height */

  color: #5843be;
  margin: 0;
}
.DB_doc_schedule_card_container .DB_doc_schedule_card:nth-child(2n) span > h6 {
  font-family: Metropolis-Medium;
  font-style: normal;
  font-weight: normal;
  font-size: 0.66em;
  line-height: 0.66em;
  /* identical to box height */
  padding-top: 0.3em;
  color: #ff5c00;
  margin: 0;
}
.DB_doc_schedule_card_container .DB_doc_schedule_card:nth-child(3n) span > h6 {
  font-family: Metropolis-Medium;
  font-style: normal;
  font-weight: normal;
  font-size: 0.66em;
  line-height: 0.66em;
  /* identical to box height */
  padding-top: 0.3em;
  color: #3d7543;
  margin: 0;
}
.DB_doc_schedule_card_container
  .DB_doc_schedule_card:nth-child(1n)
  .DB_doc_schedule_card_body {
  font-family: Metropolis;
  font-style: normal;
  font-weight: normal;
  font-size: 0.66em;
  line-height: 1.6em;
  /* or 19px */

  color: #5843be;
}
.DB_doc_schedule_card_container
  .DB_doc_schedule_card:nth-child(2n)
  .DB_doc_schedule_card_body {
  font-family: Metropolis;
  font-style: normal;
  font-weight: normal;
  font-size: 0.66em;
  line-height: 1.6em;
  /* or 19px */

  color: #ff7a00;
}
.DB_doc_schedule_card_container
  .DB_doc_schedule_card:nth-child(3n)
  .DB_doc_schedule_card_body {
  font-family: Metropolis;
  font-style: normal;
  font-weight: normal;
  font-size: 0.66em;
  line-height: 1.6em;
  /* or 19px */

  color: #3d7543;
}
/*************************************DB Schedule pane styles********************************************/
.schedule_tab_container .db_tab_container {
  margin-top: 1.5em;
}

.db_schedule_notifications_pane {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1em;
}
.active_schedule_tab {
  background: #dfe8fc !important;
}
.db_schedule_pane_detail {
  height: fit-content;
  padding: 1.1em;
  width: 100% !important;
  background: #e7eeff;
  border-radius: 0.4em;
  margin-bottom: 0.4em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.db_schedule_content_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 1em;
}
.schedule_tabs_content {
  width: 100%;
  margin-left: 0.9em;
  display: flex;
  flex-direction: column;
}
.schedule_tabs_content h5 {
  font-family: SofiaProMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 0.66em;
  line-height: 138.5%;
  /* identical to box height, or 17px */
  margin-bottom: 0.2em;
  text-transform: uppercase;
  text-align: left;

  /* Black */

  color: #071232;
}
.schedule_tabs_content .diag_shedule_category {
  text-transform: capitalize;
  font-size: 0.7em;
}
.schedule_tabs_content h6 {
  font-family: SofiaProSemiBold;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8em;
  line-height: 22px;
  /* identical to box height */
  text-align: left;
  /* Blue */
  margin-bottom: 0;
  color: $primary-blue;
}
.db_schedule_pane_detail_start_button {
  width: 4.2em;
  height: 1.9em;
  border-radius: 0.4em;
  background: $primary-blue;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.7em;
}
.db_schedule_pane_detail_start_button h6 {
  margin: 0;
  font-family: SofiaProMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 0.667em;
  line-height: 0.9em;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}
.db_schedule_pane_detail_edit_button {
  width: 1.9em;
  height: 1.9em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dfe8fc;
  border-radius: 0.4em;
}
.db_schedule_pane_doctor_detail {
  display: flex;
  width: 35%;
}
.db_schedule_pane_patient_detail {
  width: 35%;
}
.schedule_tabs_content img {
  width: 2.2em;
  height: 2.2em;
  border-radius: 50%;
  margin-right: 0.5em;
}
.DB_schedule_top .add_button {
  display: flex;
  justify-content: center;
}
.DB_schedule_top .doc_cards_search_bar {
  margin-right: 3em;
}
/*************************************DB add doc manually styles********************************************/
.pharm_form_top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2em 0 1.8em;
}
.pharm_form_top div {
  width: auto;
  //  margin-right: auto;
}
.pharm_form_top h3 {
  font-family: SofiaProMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 1.5em;
  /* identical to box height */

  /* Black */

  color: #071232;
}

.manual_add_file .file_input_container {
  width: 100% !important;
}
.pharm_form_top > div h6,
.categories_form_container h5 {
  @include label_subheading(none, #8e919c);
}
.pharm_form_top button {
  width: fit-content;
}
.pharm_form_bottom {
  padding: 1.3em 1em 2em;
  background: rgba(223, 232, 252, 0.3);
  border-radius: 0.72em;
}
.pharm_form_bottom form > div:first-child {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5em;
}
.pharm_inventory_form input,
.pharm_inventory_form .edit_input_container {
  background: #ffffff;
  border: none;
  font-family: SofiaProRegular;
  color: black;
}
.pharm_form_bottom .add_patient_inputs {
  border-bottom: none;
}
.pharm_form_bottom form > div:last-child {
  display: grid;

  place-content: flex-start;
  grid-template-columns: repeat(auto-fill, 14em);
  grid-gap: 0.8em;
}
.pharm_inventory_form_bottom {
  display: flex !important;
}
.pharm_inventory_form_bottom > button {
  margin-top: auto;
}
.pharm_form_bottom form textarea {
  background: #ffffff;
  border-radius: 0.42em;
  height: 5.7em;
  width: 60%;
  border: none;
  padding: 1em;
  border-top: none;
}
textarea::placeholder {
  font-size: 0.667em;
  color: #8e919c;
}

.pharm_form_bottom .file_input_container h6 {
  color: $primary-blue;
  margin-bottom: 0.41em;
}
.other_methods_button {
  background: #071232;
  border-radius: 0.42em;
  height: 2.4em;
}
.pharm_form_submit_button {
  background: $primary-blue;
  width: 12em;
}
.image_upload_input {
  display: none;
}
.image_upload_input_button {
  width: 5.7em;
  height: 5.7em;
  border-radius: 50%;
  background-color: #8e919c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 1em;
  opacity: 0.8;
  transition: opacity 0.2s ease;
}
.image_upload_input_button:hover {
  opacity: 1;
}
.image_upload_input_button div {
  margin: 0.8em 0 0.4em;
  height: 1em;
  width: 1em;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image_upload_input_button h6 {
  font-family: SofiaProRegular;
  font-weight: lighter;
  font-size: 0.667em;
  line-height: 0.9em;
  /* identical to box height */

  color: #ffffff;
}
.orders_counter-container {
  margin: 2em 0;
  display: grid;
  place-content: flex-start;
  grid-gap: 0.7em;
  grid-template-columns: repeat(auto-fill, 23%) !important;
}
.pharm_form_bottom .css-yk16xz-control {
  min-height: 0px !important;
  height: 1.9em;
  border: none;
  width: 100%;
}
.pharm_form_last_bottom .all_drop_drown_container {
  width: 100% !important;
}
.pharm_form_last_bottom h6 {
  color: $primary-blue;
}
.all_drop_down_top {
  display: flex;

  align-items: center;
}
.drop_down_counter {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: $primary-blue;
  margin-left: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.3em;
}
.drop_down_counter h6 {
  color: #ffffff;
  font-size: 0.6em;
  margin: 0 0 0.2em 0;
}
.pharm_inventory_form .css-yk16xz-control {
  width: 100%;
}
/*************************************Patient card cont ********************************************/

.patient_card_container {
  display: grid;
  display: -moz-grid;
  place-content: flex-start;
  grid-gap: 1em;
  grid-template-columns: repeat(auto-fill, 11.3em);
}
.doc_profile_search_bar {
  margin: 0.7em 0;
  width: 100%;
}
.search_bar {
  display: flex;
  flex-direction: row;
  height: auto;
  background: #f3f4f8;
  border-radius: 0.4em;
  height: 2.4em;
  align-items: center;
}

.search_bar input {
  border: none;
  background: none;
}
.search_bar button {
  background: none;
  width: 10%;
}

.settings_search_bar .search_bar {
  background: #eff2fe;
}

/*************************************DB schedule prompt styles ********************************************/
.modify_schedule_backdrop {
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 24, 57, 0.35);
  z-index: 999;
}
.modify_schedule_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: auto;
  width: 100%;
  height: 100%;
}
.modify_schedule_prompt {
  width: 28.4em;
  height: 27.5em;
  border-radius: 0.4em;
  background: #ffffff;
  padding: 1em 1.4em 2.2em;
  overflow-y: scroll;
  position: relative;
}

.modify_schedule_head {
  height: 3.2em;

  border-bottom: 0.06em solid #f5f5f5;
  display: flex;
  flex-direction: row;

  background: #ffffff;
}
.modify_schedule_head > div:first-child {
  margin: auto 0;
}
.close_schedule_prompt {
  background: #dfe8fc;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 1.5em;
  width: 1.5em !important;
  border-radius: 50%;
  margin: auto 0 auto auto;
  padding: 0.3em;
}
.close_prompt {
  margin-right: 0.1em;
}

.modify_schedule_head h3 {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1em;
  line-height: 32px;

  /* Blue */

  color: $primary-blue;
}
.modify_schedule_head h5 {
  font-family: SofiaProRegular;
  font-size: 0.8em !important;
  font-style: normal;
  font-weight: normal;
}
.modify_schedule_form_one,
.modify_schedule_form_two {
  display: flex;
  flex-direction: row;
}
.modify_schedule_form_one {
  margin-top: 1.3em;
}
.modify_schedule_form_two .react-select__control,
.modify_schedule_form_two .react-select__control:hover,
.modify_schedule_form_two .react-select__control:focus,
.modify_schedule_form_two .react-select__control--is-focused,
.modify_schedule_prompt input {
  border: 0.05em solid $primary-blue;
  outline: none;
  box-shadow: none;
}
.activity_input {
  height: 2.4em !important;
}
.modify_schedule_form_one > input,
.modify_schedule_prompt input {
  padding: 0 12px;
  border: 1px solid $primary-blue;
}
.modify_schedule_form_two > div:first-child,
.modify_schedule_form_one > input:first-child {
  width: 48% !important;
  margin-right: auto;
}
.modify_schedule_form_two > div:last-child,
.modify_schedule_form_one > input:last-child {
  width: 48% !important;
  margin-left: auto;
}

.modify_schedule_prompt textarea {
  border: 0.05em solid $primary-blue;
  border-radius: 0.2em;
  width: 100%;
  margin-top: 8px;
  height: 5.8em;
  padding: 0 8px;
}
textarea:focus {
  outline: none;
}
.modify_schedule_prompt button {
  width: 100%;
}
.manage_doctors_prompt {
  width: 25em;
  height: 14em;
}
.mid_height_prompt {
  width: 25em;
  height: 18em;
}
.manage_doctors_prompt_head {
  height: 6em;
}
.mid_height_prompt_head {
  height: 4em;
}
.doc_prompt_no {
  background-color: rgb(34, 84, 211);
  border-radius: 0.4em;
  margin-top: 0.5em;
  height: 2.4em;
}

.doc_prompt_yes {
  background-color: #f01919;
  border-radius: 0.4em;
  height: 2.4em;
}
.doc_prompt_no h6,
.doc_prompt_yes h6 {
  color: #ffffff;
  margin-bottom: 0;

  text-align: center;
}
/*************************************DB Patient styles ********************************************/

/*************************************DB patients table styles ********************************************/
.patient_list_table_container {
  padding: 1em 1.5em 2.4em;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none !important;
  }
}
.patient_list_table {
  width: 100%;
  overflow: scroll;
  border-collapse: separate;
  border-spacing: 0;
  position: relative;
  &::-webkit-scrollbar {
    display: none !important;
  }
}

.patient_list_table::-webkit-scrollbar {
  display: none !important;
}

.patient_list_table_head th {
  /* Normal text */

  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 0.76em;
  line-height: 22px;
  /* identical to box height */
  padding: 0.8em 0;
  /* Label & subheading */

  color: #8e919c;
}
.patient_list_table_texts {
  vertical-align: center;
  cursor: pointer;
  border-radius: 10px;
}
.patient_list_table_texts td {
  height: 0.8em;
  padding: 0.8em 0;
  border-bottom: 1px solid #dfe8fc;
}
.patient_list_table_texts td h6 {
  margin: auto 0;
  font-family: SofiaProRegular;
  line-height: 1.9em;
  font-style: normal;
  font-weight: 500;
  font-size: 0.76em;
  color: $primary-blue;
}
.patient_list_table_texts h6:nth-child(5),
.patient_list_table_texts h6:nth-child(6) {
  font-size: 0.3em !important;
}

.green_patient_type {
  text-transform: uppercase;
  color: #22d389 !important;
}
.orange_patient_type {
  text-transform: uppercase;
  color: #ff6f4f !important;
}
/*************************************DB Inputs style ********************************************/

.db_input {
  height: 1.9em;
  border-radius: 0.24em;
  background: #ffffff;
  width: 100%;
  padding: 0.4em;
  border: none;
  color: #071232;
  font-family: SofiaProRegular;
  font-weight: normal;
}
.db_input_title {
  /* Label & subheading */

  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 0.666em;
  line-height: 1.2em;
  /* identical to box height */

  /* Blue */

  color: $primary-blue;
  margin-bottom: 0.41em;
}
/*************************************DB add patients styles ********************************************/
.add_patient_container {
  display: flex;
  flex-direction: column;
}

.add_patient_container .edit_input,
.add_patient_container .edit_input_rate {
  background-color: #ffffff;
  border: none;
  height: 1.4em;
  font-size: 1.3em;
}
.add_patient_container .edit_input_container {
  height: 2.4em;
  background-color: #ffffff;
}
.add_patient_container .edit_input_container:hover {
  border: 1px solid $primary-blue;
}
.add_patient_container .edit_input {
  color: #071232;
  padding-left: 0.4em;
}
.add_patient_container .add_patient_hidden_rate,
.add_patient_container .edit_input_container button {
  display: none;
}
.add_patients_info_head,
.add_form_section_titles h3 {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 0.76em;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.1em;

  /* Blue */

  color: $primary-blue;
  text-transform: uppercase;
  margin-bottom: 0.3em;
  margin-right: auto;
}
.add_patients_info_head {
  margin-bottom: 1.7em;
}
.add_patient_inputs {
  width: 100%;
  display: grid;
  display: -moz-grid;
  place-content: flex-start;
  grid-gap: 0.5em;
  grid-template-columns: repeat(auto-fill, 24%);
  padding-bottom: 2.2em;
  border-bottom: 1px solid #e6e8ec;
}

.add_patient_inputs .fileInputContainer {
  height: 1.9em;
}
.add_patients_info_head_two {
  padding-top: 2.2em;
}
.add_patient_inputs_two {
  border-bottom: none;
}
.patient_form_submit {
  width: 10.5em;
  display: flex;
  justify-content: center;
  margin-left: auto;
}
.add_patient_container {
  padding: 2em 0em 3em;
}
.add_patient_inputs_form_container {
  padding: 0 0.7em;
}
.db_thin_btn {
  height: 1.2em;
  width: auto;
  border-radius: 0.24em;
  background: #071232;
  padding: 0 0.4em;
}
.db_thin_btn_text {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 0.666em;
  line-height: 0.8em;
  /* identical to box height */

  /* White */
  white-space: nowrap;
  color: #ffffff;
  margin: 0;
}
.manual_add_patient_container .db_input::placeholder,
.manual_add_patient_container .react-select__placeholder {
  font-size: 0.66em !important;
}
.manual_add_patient_container .all_drop_down_top h6 {
  margin-bottom: 0 !important;
}
.manual_add_patient_container .all_drop_down_top {
  margin-bottom: 0.41em;
}
.add_patient_top {
  display: flex;
  flex-flow: row wrap;
  margin-top: 2em;
  justify-content: space-between;
}
.add_patient_top > div:first-child h3 {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 1.14em;
  line-height: 1.57em;
  /* identical to box height */

  /* Black */

  color: #071232;
}
.add_patient_top > div:first-child h6 {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 0.76em;
  line-height: 1em;
  /* identical to box height */

  /* Black */

  color: #8e919c;
}
.add_patient_top_form {
  display: flex;
  flex-direction: row;
  width: auto;
}
.add_patient_top_form .all_drop_drown_container,
.add_patient_top_form .react-select__control,
.add_patient_top_form .db_input_container {
  width: 14em !important;
  margin-right: 1.4em;
}
.add_patient_top_form .react-select__control,
.add_patient_top_form input {
  background: #f5f8fe;
}

.add_patient_top .db_thin_btn {
  margin: 1em 0 0 1em;
}
.add_patient_drop_text {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 0.666em;
  line-height: 0.8em;
  /* identical to box height */

  /* Blue */

  color: $primary-blue;
}

.db_tab_body_title_top {
  display: flex;
  flex-direction: row;
  margin-top: 1.7em;
}

.manual_add_patient_container .css-yk16xz-control {
  min-height: 0px !important;
  height: 1.9em !important;
  border: none !important;
}
.manual_add_patient_container .react-select__menu {
  width: 70% !important;
  margin-left: 2em;
}
.manual_add_patient_container .all_drop_drown_container {
  width: 100% !important;
}
.manual_add_patient_container .all_drop_down_top h6 {
  margin-bottom: 0.41em;
  color: $primary-blue;
}

/*************************************DB patients profile styles ********************************************/
.db_patient_profile_container_top {
  display: flex;
  flex-flow: row;
  margin-bottom: 1.3em;
}
.db_patient_profile_container_top_left {
  width: 57%;
  margin-right: 0.4em;
}
.db_patient_profile_container_top .doctor_card {
  height: 100%;
  padding: 1.5em 1.5em;

  border-radius: 0;
  background: none;
}
.db_patient_profile_container_top .doctor_card .doctor_card_caption {
  margin: 0;
}
.db_patient_profile_container_top .doctor_card .doctor_card_spec {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 0.57em;
  line-height: 16px;
  text-align: center;

  /* Label & subheading */

  color: #8e919c;
}
.db_patient_profile_container_top .doctor_card img {
  width: 4.7em !important;
  height: 4.7em !important;
}
.db_patient_profile_container_top_inputs_outer {
  width: 100%;
  padding: 1.9em 0.78em;
  border-left: 1px solid #dfe8fc;
}
.db_patient_profile_container_top_inputs {
  display: grid;
  place-items: flex-start;
  grid-template-columns: repeat(auto-fit, 7em);
  grid-gap: 0.7em;
  grid-auto-flow: dense;
  width: 100%;
}
.db_patient_profile_top_inputs_address {
  margin-top: 0.7em;
  width: 85%;
}
.db_patient_profile_top_inputs {
  background: #dfe8fc;
}

.db_patient_profile_top_inputs {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 0.76em;
  line-height: 22px;
  /* identical to box height */

  /* Blue */

  color: $primary-blue;
}
.db_patient_profile_top_inputs:hover {
  border: none;
}
.db_patient_files_container {
  display: flex;
  flex-direction: column;
  width: 43%;
  margin-left: 0.5em;
  padding: 0 1.4em 1.2em;
}
.db_patient_files_container_head {
  height: 2.6em;
  border-bottom: 1px solid #dfe8fc;
}
.db_patient_files_container_head h3 {
  /* Normal text */

  font-family: SofiaProMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 22px;
  /* identical to box height */

  /* Black */

  color: #071232;
}
.db_patient_files_container_content {
  display: grid;
  place-content: flex-start;
  grid-template-columns: repeat(auto-fill, 5.5em);
  grid-gap: 0.3em;
  margin-top: 0.85em;
}
.pdf_file_cont {
  height: auto;
  width: 100%;
  display: grid;
  place-items: center;
  padding: 1em 0 0.8em;
  background: rgba(223, 232, 252, 0.3);
  border-radius: 0.4em;
}
.pdf_file_cont img {
  width: 6em;
  height: 6em;
  border-radius: 50%;
}
.pdf_file_type,
.pdf_file_name {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 0.57em;
  line-height: 110%;
  /* or 13px */

  text-align: center;

  /* Black */
  margin: 0.1em 0;
  color: #071232;
  background: none;
  border: none;
  padding: 0.1em 0.8em;
}

.pdf_file_type:disabled,
.pdf_file_name:disabled {
  border: none;
}
.pdf_view_btn {
  background-color: #dfe8fc;
  margin-top: 0.5em;
  width: 100%;
  padding: 0.1em;
}
.pdf_view_btn a,
.pdf_view_btn h6 {
  color: $primary-blue;
}
/*************************************Medical records styles ********************************************/
.medical_records_table {
  width: 100%;
  padding: 1.5em 0.1em;
}
.medical_records_table_tabs {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.consulting_room_tab {
  background-color: #dfe8fc;
  height: 40px;
  transition: border-bottom 0.2s linear;
}
.consulting_room_tab h6 {
  font-family: SofiaProMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 0.9em;
}
.consulting_room_tab_container {
  width: 90%;
  border-radius: 10px;
  text-align: left;
}
.active_db_consult_tab {
  background: #2254d3;
  /* Basic shadow */
  box-shadow: 0px 8px 19px rgba(34, 84, 211, 0.15);
  border-radius: 10px;
  color: white;
}

.med_record_tab {
  margin: auto;
  width: auto;
  padding-bottom: 0.8em;
  padding-right: 0.3em;
  background: none;
  transition: border-bottom 0.2s linear;
}

.active_db_tab {
  margin: auto;
  width: auto;
  padding-bottom: 0.8em;
  padding-right: 0.3em;
  background: none;
  border-bottom: 0.14em solid $primary-blue;
}
.consulting_room_tab_active {
  background: #2254d3;
  width: 140px;
  height: 35px;
  margin-top: 8px;
  /* Basic shadow */
  box-shadow: 0px 8px 19px rgba(34, 84, 211, 0.15);
  border-radius: 10px;
}
.consulting_room_tab_active > h6 {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 0.9em;
}
.med_record_tab_active > h6 {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 0.66em;
  line-height: 0.9em;
  /* identical to box height */

  /* Label & subheading */

  color: $primary-blue;
  white-space: nowrap;
  margin: 0;
}
.medical_records_table_tabs .med_record_tab:last-child {
  padding-right: 0;
}
.med_record_tab > h6 {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 0.66em;
  line-height: 0.9em;
  /* identical to box height */

  /* Label & subheading */

  color: #8e919c;
  white-space: nowrap;
  margin: 0;
}
.active_db_tab h6 {
  color: $primary-blue;
}
.add_doc_manually_checkbox {
  margin-left: 16px;
}
.add_doc_manually_checkbox div {
  margin-bottom: 8px;
}

.empty_med_record_container {
  width: auto;
  display: flex;
  margin: 1em;
  justify-content: space-between;
  align-items: center;
}
.empty_med_record_container .add_patients_info_head {
  margin: 0;
}
.med_record_tabs_add_button_container {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.7em;
}
/*************************************DB tab styles ********************************************/
.db_tabs {
  width: 100%;
}
.db_tab_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid #e6e8ec;
}
.db_tab_content_container {
  width: 100%;
}
/*************************************DB patient history tab styles ********************************************/
.patient_history_back_container .add_patient_inputs {
  grid-template-columns: repeat(auto-fill, 13em);
}
.patient_history_notes {
  padding: 1.5em 0 2.2em 0;
  border-bottom: 1px solid #e6e8ec;
}
.patient_history_notes textarea,
.db_med_record_diagnosis_content textarea {
  width: 100%;
  padding: 1em;
  border-radius: 0.24em;
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: 300;
  font-size: 0.76em;
  border: none;
}
/*************************************DB patient examination tab styles ********************************************/

.exam_back_container .db_input_title,
.exam_back_container .note_tag {
  text-transform: capitalize;
  font-family: SofiaProMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 0.667em !important;
  line-height: 0.9em !important;
  color: #071232;
}
.exam_back_container .add_patient_inputs {
  grid-template-columns: repeat(auto-fill, 13em);
}
.patient_feeling_container {
  display: flex;
}
.patient_feeling_container div {
  width: 100%;
}
/*************************************DB patient diagnosis tab styles ********************************************/
.diagnosis_back_content_one,
.diagnosis_back_content,
.diagnosis_last_back {
  display: flex;
}

.diagnosis_back_content .react-select__single-value {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 0.76em;
  line-height: 1.9em;
  color: $primary-blue;
  margin-top: -0.2em;
}
.db_med_record_diagnosis_content {
  width: 100%;
}
.db_med_record_diagnosis_content h6 {
  margin-right: auto;
}
.db_med_record_diagnosis_content div {
  height: auto;
  padding-bottom: 2em;
  background: #dfe8fc;
  border-radius: 0.24em;
}
.test_result_top .db_thin_btn {
  margin-left: auto;
}
/*************************************Diag main home styles ********************************************/
.diag_main_home_table_container {
  padding-top: 0em;
}
.diag_main_home_table tr:first-child {
  border-bottom: 1px solid #dfe8fc;
}
.diag_main_home_table th {
  text-align: left !important;
  color: $primary-blue;
  font-family: SofiaProMedium;
}
.diag_main_home_table button {
  height: auto;
  border-radius: 0.6em;
  width: 8em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.7em 1em;
  margin-left: auto;
}
.diag_main_home_table button > h6 {
  font-size: 1em;
  margin-left: 0.5em;
}
.diag_main_home_table_test {
  font-family: SofiaProBold;
  font-style: normal;
  font-weight: normal;
  font-size: 0.76em;
  line-height: 1.1em;

  /* Black */

  color: #071232;
}
.diag_main_home_table_patient {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 0.667em;
  line-height: 0.9em;
  /* identical to box height */

  /* Label & subheading */

  color: #8e919c;
}
/*************************************Prescriptionstyles ********************************************/
.add_patient_inputs_form_container_class_two .css-1wa3eu0-placeholder,
.add_patient_inputs_form_container_class_two .react-select__input input,
.add_patient_inputs_form_container_class_two .css-1uccc91-singleValue {
  font-size: 0.76em !important;
  color: $primary-blue !important;
  font-family: SofiaProRegular !important;
  top: 50%;
}

.add_patient_inputs_form_container_class_two .css-6q0nyr-Svg {
  margin-top: -0.4em !important;
}
.prescription_content_one_container .css-1wa3eu0-placeholder {
  top: 30%;
  font-size: 0.6em;
  color: #8e919c;
}
.prescription_content_one_container .db_input::placeholder {
  font-size: 0.76em;
  color: #8e919c;
}
.prescription_content_one_container .react-select__value-container {
  padding: 2px 4.9px;
}
.prescription_content_one_container .react-select__input {
  height: auto !important;
  display: flex !important;
  margin-top: -0.5em;
}
.prescription_content_one_container .react-select__input input {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 0.76em !important;

  line-height: 0.76em !important;
  color: $primary-blue !important;
}
.prescription_content_one_container .react-select-container {
  height: 1.9em;
}
.add_patient_inputs_form_container_class_two .react-select-container,
.add_patient_inputs_form_container_class_two .css-yk16xz-control,
.add_patient_inputs_form_container_class_two .react-select__control,
.add_patient_inputs_form_container_class_two .css-yk16xz-control--is-disabled {
  min-height: 1.5em !important;
  height: 1.5em !important;
  background: rgba(223, 232, 252, 0.3);
  border: none;
  border-radius: 0.24em;
}

.prescription_content_one_container .react-select-container:nth-child(2) {
  margin-bottom: 0.7em;
}
.prescription_content_one_container .db_input {
  margin-bottom: 0.5em;
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 0.76em;
  line-height: 22px;
  color: $primary-blue;
  height: 2.5em;
}
.prescription_content_one_container .db_input:disabled {
  background-color: hsl(0, 0%, 95%);
}

.prescription_content_one_container .css-yk16xz-control {
  min-height: 1.9em !important;
  height: 1.9em !important;
  border: none;
}

.prescription_content_one_container .react-select__control {
  min-height: 1.9em !important;
  height: 1.9em !important;
  border: none;
}
.prescription_content_one_container .css-yk16xz-control--is-disabled {
  min-height: 1.9em !important;
  height: 1.9em !important;
  border: none;
}
.prescription_sub_container {
  display: grid;
  place-content: center;
  grid-gap: 2%;
  grid-template-columns: repeat(auto-fill, 48%);
}
.prescription_content_one_container .all_drop_down_top {
  display: none;
}

/*************************************Med note styles ********************************************/
.note_tag {
  /* Label & subheading */

  font-family: SofiaProMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 0.667em !important;
  line-height: 0.9em !important;
  margin-left: 12px;
  /* identical to box height */

  /* Black */

  color: #071232;
}

.note_content {
  width: 100%;
  padding: 1em;
  border-radius: 0.24em;
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: 300;
  font-size: 0.76em;
  line-height: 138.5%;
  /* or 22px */

  color: #364354;
  background: #ffffff;
  border: none;
}
.billing_note_content {
  border: none;
  width: 100%;
}
/*************************************date drop styles ********************************************/
.db_date_drop {
  width: auto;
  padding: 0.2em 0.3em;
  height: fit-content;
  background: #071232;
  border-radius: 0.24em;
  display: flex;
  justify-content: center;
  margin-left: auto;
}
.db_date_drop > h6 {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 0.66em;
  line-height: 0.9em;
  /* identical to box height */

  /* White */
  margin: 0;
  color: #ffffff;
}
.db_date_drop > div {
  width: 0.71em;
  height: 0.71em;
  border-radius: 50%;
  background: rgba(223, 232, 252, 0.21);
  display: grid;
  place-items: center;
  margin-left: 0.4em;
}
.db_date_drop > div img {
  width: 0.29em;
  height: 0.16em;
}
/*************************************edit input styles ********************************************/
.general_billing_form_container .react-select__control {
  background-color: #dfe8fc;
  border: none;
}
.general_billing_form_container .all_drop_drown_container {
  margin-top: 0.4em;
}
.general_billing_form_container .all_drop_drown_container h6 {
  color: #071232;
}
.black_input_tag {
  /* Label & subheading */

  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 0.666em;
  line-height: 19px;
  /* identical to box height */

  /* Black */
  margin-bottom: 0.41em;
  color: #071232;
}
.black_input_tag_top {
  background: none;
  border: none;
  padding: 0 0.2em 0 0;
}
.black_input_tag_top:disabled {
  border: none;
}
.edit_input_top_container {
  display: flex;
  flex: row;
  justify-content: space-between;
}
.edit_input_two_container {
  margin-bottom: 0.5em;
}
.hmo_billng_forms_container {
  grid-template-columns: repeat(auto-fill, 48%);
}
.hmo_billing_form .edit_input_icon {
  width: fit-content;
}
.hmo_billing_form .edit_input_rate_2 {
  width: 15%;
}
.hmo_billing_form .edit_input_rate {
  font-weight: bold;
  width: 25%;
}
.hmo_billing_form .edit_input_three_container .edit_input_rate_2,
.hmo_billing_form .edit_input_one_container .edit_input_rate_2 {
  display: none;
}
.edit_input_three_container {
  margin-top: 0.5em;
}
.edit_input_top_container button {
  background: none;
  width: fit-content;
  height: fit-content;
}
#Capa_1 {
  cursor: pointer;
  margin-right: 0.3em;
}
.edit_input_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: fit-content;
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 0.76em;
  line-height: 22px;
  color: $primary-blue;
  background: #dfe8fc;
  border-radius: 0.24em;
}
.edit_input_container h6 {
  display: none;
}
.edit_input {
  font-family: SofiaProRegular;
  font-style: normal;
  width: 100%;
  color: $primary-blue;
  background: #dfe8fc;
  padding: 0;
}
.edit_input:active,
.edit_input:focus,
.edit_input:hover {
  border: none;
}
.consult_note {
  margin-top: 0.4em;
}
.edit_input_rate {
  background: #dfe8fc;
  color: $primary-blue;
  padding: 0 5px;
  width: 40%;
}
.calls_visit_input {
  width: 125%;
}
.edit_input_rate:focus,
.edit_input_rate:hover {
  border: none;
}
.edit_input_rate_2 {
  width: 50%;
  padding-left: 2px;
}
.unit_rate_input {
  width: 50%;
}
.edit_input_icon {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 0.5em;
  background: none;
}
/*************************************DB billing styles ********************************************/
.db_billing_table_tabs {
  padding: 0 2em;
}
.db_billing_table_tabs .db_tab_body_title_top {
  padding: 0;
}
.db_billing_tab {
  margin: 0 3em 0 0;
}

.db_billing_save_btn {
  height: 2.4em;
  border-radius: 0.42em;
  margin: 0.4em 0 0.6em;
}
/*************************************DB payment history styles ********************************************/
.db_payment_history-container {
  padding: 0 2em;
}
.payment_history_top {
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2.2em 0 1.1em;
}
.payment_history_top h3 {
  font-family: SofiaProMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 0.76em;
  line-height: 1em;
  /* identical to box height */

  letter-spacing: 0.1em;

  /* Blue */
  text-transform: uppercase;
  color: $primary-blue;
}
.payment-rates_container {
  display: flex;
  flex-direction: row;
}
.payment-rates_container .rate_card:first-child {
  margin-right: 1em;
}

/*************************************Rate Card styles ********************************************/
.rate_card {
  width: auto;
  padding: 0.7em 1.1em;
  background: #ffffff;
  border-radius: 0.7em;
}
.rate_card_top,
.rate_card_down {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.rate_card span {
  display: flex;
  flex-direction: row;
}
.rate_card_top h3 {
  font-family: SofiaProBold;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5em;
  line-height: 1.6em;

  /* Black */

  color: #071232;
}
.rate_card_down > h6 {
  font-family: SofiaProMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 0.76em;
  line-height: 22px;
  /* identical to box height */

  /* Blue */

  color: $primary-blue;
}
.rate {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 0.666em;
  line-height: 0.9em;
  /* identical to box height */

  color: #22d389;
}
/*************************************DB rentals styles ********************************************/
.available_rental_facility {
  display: flex;
  flex-direction: row;
}
.available_rental_facility table {
  margin-right: 1.1em;
}
.new_facility_form {
  width: 18em;
  height: 14em;
  background: rgba(223, 232, 252, 0.3);
  border-radius: 1em;
  padding: 1.5em 1.1em 1.5em 1.4em;
}
.new_facility_form_container h5 {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8em;
  line-height: 1em;
  /* identical to box height */

  /* Blue */

  color: $primary-blue;
}
.new_facility_form h6:not(:last-child),
.manage_rentals_prompt h6:not(:last-child) {
  /* Label & subheading */

  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 0.667em;
  line-height: 0.9em;
  margin-top: 0.5em;
  /* identical to box height */
}
.new_facility_form h6:nth-child(2) {
  padding-top: 0.8em;
}
.new_facility_form button {
  margin-top: 1em;
  height: 2.4em;
  background: #22d389;
  border-radius: 0.4em;
}
.manage_rental_form_input {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 0.76em;
  color: $primary-blue;
}
/*************************************DB rental prompt styles ********************************************/
.rental_request_prompt-container {
  margin-top: 1em;
}
.rental_request_prompt-container > div:first-child {
  display: grid;
  place-content: flex-start;
  grid-gap: 0.8em;
  grid-template-columns: repeat(auto-fill, 47%);
}
.rental_request_prompt-container > div > div:nth-child(odd) {
  text-align: left;
}
.rental_request_prompt-container > div > div:nth-child(even) {
  text-align: right;
}
.rental_request_prompt-container h6 {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8em;
  line-height: 1em;

  /* Label & subheading */

  color: #8e919c;
}
.rental_request_prompt-container h5 {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 0.96em;
  line-height: 0.96em;
  /* identical to box height */

  /* Black */

  color: #071232;
}
.date_of_usage {
  color: $primary-blue !important;
}

.rental_request_prompt-container h4 {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8em;
  line-height: 138.5%;
  /* or 22px */

  /* Black */

  color: #071232;
}
.rental_request_prompt-container button {
  width: 8.42em;
  height: 2.4em;
  border-radius: 0.4em;
}
.rental_request_prompt-container button:first-child {
  background-color: #22d389;
  margin-right: 0.45em;
}
.rental_request_prompt-container button:last-child {
  background-color: #f01919;
}

.manage_rentals_prompt {
  width: 19em !important;
  height: 19.4em !important;
}
.manage_rentals_prompt form button:last-child {
  background: #22d389;
  border-radius: 0.4em;
  height: 2.4em;
  margin-top: 1em;
}

.econsult_prompt_form .modify_schedule_head {
  position: absolute;
  width: 90%;
}
.econsult_prompt_form .rental_request_prompt-container {
  margin-top: 6em;
}
.econsult_prompt_form_submit {
  border-top: 1px solid #e6e8ec;
}
.econsult_prompt_form_submit textarea {
  margin-top: 0.5em;
  height: 4em;
}
.econsult_prompt_form_submit > button {
  background: rgb(34, 84, 211) !important;
  margin-left: auto;
}
/*************************************DB settings styles ********************************************/
.table_images {
  width: 1.67em;
  height: 1.67em;
  margin-right: 0.4em;
}

.db_settings_table_tabs .db_billing_tab:first-child {
  margin-left: 2em;
}
.db_settings_table_tabs .db_settings_subtab:first-child {
  margin-left: 0 !important;
}
.db_settings_table_tabs .mini__accordion {
  background-color: #dfe8fc;
}
.db_settings_table_tabs .mini__accordion__section {
  background-color: #dfe8fc;
}
.add_admin_prompt_container {
  padding-top: 0;
}
.add_admin_prompt {
  display: grid;
  grid-template-columns: repeat(auto-fill, 47%);
  grid-gap: 6%;
  margin-top: 1.3em;
  margin-bottom: 1em;
}
.modify_schedule_body .db_input_title {
  color: #071232;
}
.staff_settings_title {
  margin-right: auto;
}
.add_admin_prompt .label_subheading {
  margin-bottom: 0.5em;
  color: #071232;
}
.add_admin_prompt .react-select__control {
  border: 0.05em solid $primary-blue;
  outline: none;
  box-shadow: none;
}
.add_admin_prompt .react-select__control:hover {
  border: 0.05em solid $primary-blue;
  outline: none;
  box-shadow: none;
}
.add_admin_prompt .react-select__value-container {
  height: 2.4em;
}
.add_admin_prompt h6:not(:last-child) {
  /* Label & subheading */

  font-family: SofiaProRegular;
  margin-bottom: 0.5em;
  font-style: normal;
  font-weight: normal;
  font-size: 0.666em;
  line-height: 0.9em;
  /* identical to box height */

  /* Black */

  color: #071232;
}
.add_admin_prompt input {
  height: 2.4em;
}
.add_admin_prompt .add_doc_btn {
  margin-top: 12.4px;
}
.hospital_avatar {
  width: 5.7em;
  height: 5.7em;
  background-color: #8e919c;
  border-radius: 50%;
}

.hos_profile_primary_info_top {
  display: flex;
  flex-direction: row;
  margin: 1.5em 0;
}
.hos_profile_primary_info_top textarea {
  width: 65%;
  margin-left: 1em;
}
.hos_profile_primary_info_down {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: center;
}
.hos_profile_primary_info_form {
  width: 100%;
  margin-left: 1em;
}
.hos_profile_primary_info_form input {
  height: 2.4em;
  margin-bottom: 1em;
}
.hos_profile_primary_info_form input[value],
.settings_file_input h5 {
  color: #071232;
  font-family: SofiaProRegular;
  font-weight: 500;
}
.settings_file_input h5 {
  font-size: 0.9em;
  margin-bottom: 0;
  position: absolute;
  width: 60%;
}
.settings_file_input svg {
  z-index: 999;
}
.file_input_value_text {
  position: relative;
  height: 100%;
  padding-right: 2em;
  display: flex;
  align-items: center;
}
.settings_file_input {
  margin-bottom: 1em;
  height: auto;
  width: auto;
}

.settings_file_input .fileInputContainer {
  padding: 0.4em;
  height: 2.4em;
}
.hos_profile_primary_info_form .react-select-container {
  margin-bottom: 1em;
}
.hos_profile_primary_info_form .react-select__control {
  border: none;
}
.hos_profile_primary_info_form button {
  width: 100%;
  background: $primary-blue;
}
.hos_profile_primary_info_form h6:not(:last-child) {
  /* Label & subheading */

  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 0.666em;
  line-height: 0.9em;
  /* identical to box height */

  /* Label & subheading */
  margin-bottom: 0.3em;
  color: #8e919c;
}
.hos_profile_table_body .checkBox {
  height: fit-content;
  width: fit-content;
  padding: 0;
  background: none;
}
.hos_profile_table_body .all__checkbox > div {
  width: 1.1em;
  height: 1.1em;
  border-radius: 0.24em;
}

.hos_profile_table_container {
  background: #ffffff;
  border-radius: 0.4em;
  padding: 0.6em 0.6em 2em;
  width: fit-content;
  height: fit-content;
}
.hos_profile_table_container .patient_list_table_head th {
  text-align: center;
}

.hos_profile_table_body td:not(:first-child) {
  font-size: 0.7em;
}
.hos_profile_table_body td {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: normal;

  line-height: 1em;

  /* Black */

  color: #071232;
  height: 0.8em;
  padding: 0.8em 0.5em;
  border-bottom: 1px solid #dfe8fc;
}
.hos_profile_table_body td:nth-child(2) {
  text-transform: uppercase;
  padding: 0.4em 0;
}
.hos_profile_table_body td:first-child {
  padding: 0.4em 0;
}
.hos_profile_table_body input[type="time"] {
  height: 2.4em;
  width: 8em;

  padding: 0.6em 0.2em;
}
.hos_profile_table_body input[type="time"] ::-webkit-calender-picker-indicator {
  background: none;
  opacity: 0;
}

/*************************************Pharmacy setup  styles********************************************/
.pharmacy_drug_form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
}
.pharmacy_drug_form > input {
  margin: 0.7em 0;
}
.pharmacy_drug_form > button,
.add_email_fields_btn > button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  padding: 0.3em 0.45em;
  background: #dfe8fc;
  border-radius: 1.9em;
}
.add_email_fields_btn {
  margin: 2.5em 0 0 0;
}
.pharmacy_drug_form h6,
.add_email_fields_btn h6 {
  margin-bottom: 0;
}
.pharmacy_drug_form > button > div,
.add_email_fields_btn > button > div {
  background: $primary-blue;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.3em;
}
/*************************************Pharmacy sidenav styles********************************************/
.pharmacy_menu_item {
  margin-left: 1em !important;
  width: 6em !important;
}
.pharmacy_menu_item svg {
  margin-right: 0.7em !important;
}
/*************************************Orders Modal ********************************************/

.orders_modal {
  background: #f3f4f8;
  border-radius: 0.24em;
  padding: 1em 3em 1em 1em;
}
.orders_modal h6 {
  font-family: Metropolis;
  font-weight: 500;
  font-size: 0.8em;
  color: #8e919c;
}
.orders_modal h5 {
  font-family: Metropolis-Black;
  font-weight: normal;
  font-size: 1.2em;
  color: #245de8;
}

.orders_counter-container {
  margin: 2em 0;
  display: grid;
  place-content: flex-start;
  grid-gap: 0.7em;
  grid-template-columns: repeat(auto-fill, 11em);
}
.click_helper_button {
  width: fit-content;
  height: fit-content;
  background: none;
}
/*************************************Table accordion styles ********************************************/
.transparent {
  color: transparent !important;
}
.table_accordion_row .all_cb_container {
  margin-top: -15px;
}
.table_accordion_row button {
  height: 1.4em;
  width: 1.4em;
  background-color: #dfe8fc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.table_accordion_inner > td,
.table_accordion_inner h6 {
  padding: 0 !important;
}

.table_accordion_row > td > button {
  margin: -15px 1em 0 auto;
}
.tab_rotate {
  transform: rotate(180deg);
}
.table_accordion_inner_content th {
  padding: 0.3em 1em;
}
.db_pharm_orders_table_accordion h6 {
  margin: 0;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none !important;
  }
}

.table_accordion_row_container {
  width: 100%;
}

.checkbox_head_cell {
  width: 1% !important;
}
.checkbox_head_cell .all_cb_label::before {
  width: 1.75em !important;
  height: 1.75em !important;
}

.pharm_orders_details_body td {
  padding: 0.8em 0.4em;
  border-bottom: 1px solid #dfe8fc;
}
.pharm_orders_details_body h6 {
  font-family: "Metropolis";
  font-size: 0.76em;
  text-transform: capitalize;
  overflow-x: scroll;
}
.pharm_drug_image {
  width: 1.67em;
  height: 1.67em;
}
.pharm_orders_details_table {
  width: 90%;
  margin: auto;
}
.positive_table_color,
.negative_table_color,
.pending_table_color,
.rejected_table_color {
  text-transform: uppercase;
}
.positive_table_color {
  color: #22d389 !important;
}
.negative_table_color {
  color: #ff8159 !important;
}
.rejected_table_color {
  color: #f01919 !important;
}
.pending_table_color {
  color: rgb(135, 79, 255) !important;
}

/*************************************Pharmacy db styles********************************************/

.pharm_form_top h3 {
  font-family: SofiaProMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 1.5em;
  /* identical to box height */

  /* Black */

  color: #071232;
}
.pharm_form_top > div h6 {
  @include label_subheading(none, #8e919c);
}

.pharm_form_bottom {
  padding: 1.3em 1em 2em;
  background: rgba(223, 232, 252, 0.3);
  border-radius: 0.72em;
}
.pharm_form_bottom form > div:first-child {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5em;
}
.pharm_form_bottom form > div:last-child {
  display: grid;

  place-content: flex-start;
  grid-template-columns: repeat(auto-fill, 14em);
  grid-gap: 0.8em;
}
.hospital_doc_form_bottom form > div:last-child {
  grid-template-columns: repeat(auto-fill, 30%);
}
.hospital_doc_form_bottom form .react-select__control,
.hospital_doc_form_bottom .all_drop_drown_container {
  width: 100% !important;
}
.pharm_form_bottom form > div:first-child textarea {
  background: #ffffff;
  border-radius: 0.42em;
  height: 5.7em;
  width: 60%;
  border: none;
  padding: 1em;
}
textarea::placeholder {
  font-size: 0.667em;
  color: #8e919c;
}
.pharm_form_bottom .fileInputContainer,
.pharm_form_bottom .file_input_container {
  height: 1.9em;

  border-radius: 0.24em;
  border: none;
  margin-bottom: 0.8em;
}
.pharm_form_bottom .file_input_container h6 {
  color: $primary-blue;
  margin-bottom: 0.41em;
}

.pharm_form_submit_button {
  background: $primary-blue;
}
.add_delivery_charges_inputs {
  grid-template-columns: repeat(auto-fill, 24%);
  border-bottom: none;
  padding-bottom: 0.6em;
  margin-top: 2.2em;
}
.add_delivery_charges_button {
  padding: 0.3em 0;
  width: 100%;
}
.add_delivery_charges_button > button {
  margin: 0.8em 0 0 auto;
}

.pharmacy_settings_tab .db_tab_container .db_settings_subtab:nth-child(2) {
  margin: 0 !important;
  display: none;
}
/*************************************All checkbox styles ********************************************/
.all_cb_container {
  display: flex;
  align-items: center;
  position: relative;
}
.all_cb {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}
.all_cb_label {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  @include normal_text($primary-blue, uppercase);
}
.all_cb_label::before {
  content: "";
  width: 1.4em !important;
  height: 1.4em !important;
  border: 1px solid #dfe8fc;
  border-radius: 0.24em;
  margin-right: 0.9em;
  background: #ffffff;
}
.all_cb_container input[type="checkbox"]:checked + label::before {
  content: "\002713";
  background-color: $primary-blue;
  color: white;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.styled_cb_back {
  padding: 0.7em 0.5em;
  background: #dfe8fc;
  border-radius: 0.5em;
  width: 10em;
}
/*************************************DB Diagnostics styles ********************************************/

.db_diag_test_table_options {
  position: absolute;
  background: #ffffff;
  border: 1px solid #dfe8fc;
  border-radius: 0.4em;
  padding: 0.42em 0.42em 0.42em 1em;
  display: flex;
  flex-direction: column;
  right: 0;
  z-index: 99999999;
}
.db_diag_test_table_options button h6 {
  text-align: left;
}
.db_diag_test_table_options_close {
  margin-left: auto;
  width: 50% !important;
  // height: 1em;
  transition: transform 0.3s ease-out;
}
.db_diag_test_table_options_close:hover {
  transform: scale(1.2);
}
.db_diag_test_table_options_close h5 {
  color: #ff6f4f;
}
.db_diag_test_table_options button {
  background: none;
  width: fit-content;
  padding: 0.2em 0;
}
.db_diag_test_table_options h6 {
  color: #071232 !important;
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 0.5em;
  transition: transform 0.3s ease-out;
  padding-left: 0.2em;
}
.db_diag_test_table_options h6:hover {
  transform: scale(1.1);
}
.diag_patient_list_table_head {
  background: #245de8;
}
.diag_patient_list_table_head th {
  padding: 0.3em 0 0.3em 0.8em;
  color: #ffffff;
}

.diag_patient_list_table_body {
  position: relative;
}
.db_diag_test_table_options_container {
  position: relative;
}
.db_diag_test_table_options {
  position: absolute;
  width: 9em !important;
  top: 0;
}
.db_diag_test_table_options button {
  background: none !important;
}
.diag_patient_list_table_body td {
  padding-left: 0.8em;
}
.diag_patient_list_table_body td:last-child {
  padding-right: 0.8em;
}
.diag_patient_list_table_body button {
  background: #dfe8fc;

  border-radius: 0.25em;
}
.show_profile_button {
  background: linear-gradient(180deg, #0b1b4a 0%, #071232 53%, #0a1a47 100%);
  border-radius: 0.24em;
  height: 1.9em;
  margin-top: 1em;
}
.show_record_button {
  background: rgb(135, 79, 255);
  border-radius: 0.24em;
  height: 1.9em;
  margin-top: 1em;
}
.all_calender_dates > div {
  border-bottom: 1px solid rgba(142, 145, 156, 0.24);
}

/*************************************TOAST STYLE ********************************************/
.toast_container {
  position: fixed;
  top: 88vh;
  right: 0.3em;
  z-index: 999;
  height: 5em;
  width: 20em;
  display: flex;
  padding: 1.2em 0.6em;
  box-shadow: 0 0 0.2em #999;
  border-radius: 0.14em;
  transition: transform 0.3s ease-in-out;
  transform: translateX(21em);
}
.hidden_toast {
  transform: none !important;
}
.third_toast {
  top: 64vh;
}
.up_toast {
  top: 76vh;
}
.toast_container button {
  background: none;
  width: fit-content;
  height: fit-content;
  align-items: center;
  justify-content: center;
  margin: -1.2em 0.1em auto auto;
}
.toast_container h6 {
  color: #ffffff;
  margin-bottom: 0;
  font-size: 0.76em;
  font-weight: 300;
}
.toast_container svg {
  margin-right: 0.3em;
}

/*************************************  Hmo Styles ********************************************/
.add_form_section {
  display: flex;
  flex-direction: column !important;
  border-bottom: 1px solid #e6e8ec;
  padding-bottom: 1.5em;
}
.categories_form_container {
  margin-top: 1em;
  background-color: rgba(223, 232, 252, 0.3);
  border-radius: 15px;
  padding: 15px;
}
.categories_form_container .db_input_container h6,
.categories_form_container .all_drop_down_top h6 {
  @include label_subheading(none, $primary-blue);
}

.categories_form_container .styled_cb_back:not(:last-child) {
  margin-right: 1em;
}
.categories_form_container .db_input_container {
  margin-bottom: 0.5em;
}
._subgroup_container {
  padding-top: 1em;
}
._subgroup_container .edit_input_container svg {
  display: none;
}
.all_categories_form_container {
  width: 100%;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(auto-fill, 48%);
  place-content: center;
}
.mobile {
  display: none !important;
}
/*************************************MEDIA QUERIES ********************************************/

@media only screen and (max-width: 767px) {
  .no-wrap-md {
    white-space: normal;
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: flex !important;
  }
  main {
    max-width: 1200px;
    width: 100%;
  }
  .loginForm {
    width: 90%;

    margin-bottom: 150px;
  }
  .signupTwo {
    margin-bottom: 150px;
  }
  .signupOne {
    margin-bottom: 150px;
  }
  .signupThree {
    margin-bottom: 150px;
  }
  .loginFormRight {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding-right: 15px;
    margin-bottom: 50px;
  }
  .loginFormRight h1 {
    font-size: 2.28em !important;
  }
  .loginFormRight h2 {
    font-size: 1.9em !important;
  }
  .loginFormRight h3 {
    font-size: 0.76em !important;
  }
  .loginFormLeft {
    padding-left: 0;
    padding-right: 0;
  }
  .practiceBtn {
    width: 100%;
    background: $primary-blue;
    border-radius: 10px;
    font-family: Metropolis;
    font-style: normal;
    font-weight: 800;
    font-size: 0.8em;
    height: 45px;
    color: #ffffff;
    border: none;
    margin-top: 1px;
    margin-bottom: 1px;
  }

  h1 {
    font-family: Metropolis-Black;
    font-style: normal;
    font-weight: 800;
    font-size: 2.2em;
    line-height: 45px;
    color: #071232;
    text-align: center;
  }
  h2 {
    font-family: Metropolis-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 1.9em;
    line-height: 104.6%;
    color: $primary-blue;
    text-align: center;
    margin-top: 10px;
  }
  /*********************Setup MEDIA QUERIES ***********************/
  .hospital_container {
    width: 98%;
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: 100px;
  }
  .hosSetupHeader {
    align-items: flex-end;
    margin-top: 50px;
    margin-bottom: 30px;
    left: 0;
    top: 0;
  }
  .second_hos_cb {
    margin-bottom: 25px;
  }
  .tabsPane {
    border-bottom: 1px solid #e6e8ec;
    border-radius: 10px 10px 0 0;
    display: flex;
    flex-direction: row;
    height: 90px;
    position: relative;
  }
  .accordion__title {
    font-family: Metropolis;
    font-style: normal;
    font-weight: 500;
    font-size: 0.8em;
    line-height: 24px;
    color: #071232;
    margin-bottom: 0;
    transition: margin-bottom 0.6s ease;
  }
  .acc__check__box__label {
    padding-left: 10px;
    margin-bottom: 0;
    font-family: Metropolis;
    font-style: normal;
    font-weight: 600;
    font-size: 0.66em;
    color: $primary-blue;
  }
  .m_a_c_row > div {
    width: 95%;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .checkBoxGroup {
    padding: 5px;
  }
  .locationTop {
    width: 100%;
    padding: 10px;
  }
  .locationFormField > div {
    padding: 10px;
  }

  /*********************PHARMACY MEDIA QUERIES ***********************/
  .arv_tag {
    font-family: Metropolis;
    font-style: normal;
    font-weight: 500;
    font-size: 0.66em;
    line-height: 14px;
    color: $primary-blue;
    margin-bottom: 14px;
  }
  .add_drug_btn {
    width: 65%;
    margin-top: 15px;
  }

  /*********************Notifications MEDIA QUERIES ***********************/
  .notification_tab {
    display: none;
  }
  .notification_tab_content,
  .db_notifications_container {
    left: 0;
    right: 0;
    width: 100%;
    margin: 0;
  }
  .notification_tab_content {
    padding: 1.5em 1em;
  }
  /*********************Dash Board MEDIA QUERIES ***********************/

  .db_side_nav,
  .sidenav {
    display: block;
    position: absolute;
    overflow-y: scroll;
    scrollbar-width: none;
    width: 70%;
    max-width: 400px;
    background-color: #f5f7fa;
    height: 100% !important;
    top: 0;
    left: 0;
    margin-right: auto;
    z-index: 1000;
    transform: translate(-120%);
    transition: transform 0.3s ease-out;
  }

  .db_side_nav.db_side_nav_open,
  .sidenav.db_side_nav_open {
    transform: translateX(0);
  }

  .db_home_wrapper {
    width: 100%;
    height: auto;
    background: #ffffff;
    /* Card border */
    border: 1px solid #dfe8fc;
    box-sizing: border-box;
    box-shadow: 0px 8px 19px rgba(34, 84, 211, 0.15);
    margin-left: auto;
    padding-bottom: 20%;
  }
  .db_head_text {
    font-size: 0.66em;
    align-self: flex-start;
  }
  .db_head_time {
    font-size: 0.66em;
    line-height: 12px;
    letter-spacing: 0.1em;
    color: $primary-blue;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    align-self: flex-start;
  }
  .db_head_avatar {
    margin-left: auto;
    margin-right: 10px;
    align-self: flex-start;
  }
  .db_bell_tag {
    margin-left: -10px;
    margin-top: -20px;
  }
  .notification_span {
    background: #dfe8fc;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .head_images {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .modal_content {
    display: flex;
    height: auto;
    width: 90%;
    margin: 10px;
  }
  .all_db_modal_texts {
    margin-top: -16%;
    width: 100%;
    margin-left: 0;
  }
  .modal_body {
    font-family: Metropolis;
    font-style: normal;
    font-weight: 500;
    font-size: 0.66em;
    line-height: 20px;
    color: #dfe8fc;
    text-align: center;
    padding: 0 0.3em;
  }
  .modal_title {
    font-family: Metropolis;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    text-align: center;
  }
  .modal_btn {
    margin-left: auto;
    margin-right: auto;
  }
  .dbStatus {
    height: 100px;
  }
  .db_doc_head_text {
    font-size: 14px;
  }
  .add_doc_btn {
    border: none;
    width: 200px;
    height: 40px;
    border-radius: 10px;
    margin-top: 0;
  }
  .add_doc_body_text {
    text-align: center;
  }
  .add_doc_via_email_top_left {
    width: 100%;
  }
  .add_doc_via_email_top_right {
    width: 90%;
  }
  .db_add_doc_btns {
    margin-top: 15px;
  }
  .add_doc_container {
    margin-top: 50px;
  }
  .add_doc_via_email_bottom {
    width: 100%;
    height: 100%;
    margin-top: 15px;
    margin-right: auto;
    background: rgba(223, 232, 252, 0.3);
    border-radius: 15px;
    padding-bottom: 10px;
    margin-bottom: 50px;
  }
  .add_doc_via_email_bottom_content > div {
    width: 100%;
  }
  .db_doc_email_wrapper {
    height: auto;
  }

  .db_main_home_top_container {
    flex-direction: column;
  }
  .db_main_home_rate-cards,
  .db_main_home_rate_cards_bottom {
    width: 100%;
    margin: 0 0 1em 0;

    grid-template-columns: repeat(auto-fill, 48%);
  }
  /*********************************Sidenav query******************/
  .db_content {
    width: 90%;
    padding-bottom: 5em;
  }
  .db_doc_profile_control {
    display: none;
  }

  .db_patient_profile_container_top {
    flex-flow: row wrap;
  }
  .db_patient_profile_container_top_left {
    width: 100%;
    margin: 0 0 1em 0;
  }
  .db_patient_profile_container_top .doctor_card {
    padding: 1.5em 0.5em;
    width: 100%;
  }
  .db_tab_container {
    flex-wrap: wrap;
  }
  .med_record_tab {
    padding-top: 0.5em;
  }
  .add_patient_top_form,
  .db_schedule_notifications_pane,
  .hos_profile_primary_info_down {
    flex-wrap: wrap;
  }
  .hos_setup_pri_info_container > form {
    flex-direction: column;
  }
  .schedule_tabs_content {
    margin: 0;
  }
  .hos_profile_primary_info_table {
    margin-bottom: 2em;
  }
  .hos_profile_primary_info_form,
  .hos_setup_pri_Info_left,
  .hos_setup_pri_Info_right {
    margin: 0;
  }
  .hos_setup_pri_Info_left,
  .hos_setup_pri_Info_right {
    width: 100%;
  }
  .finishBtn {
    width: 6em;
    position: absolute;
    right: -1.5em;
    top: 0.5em;
  }

  .tabs .tab {
    margin: 25px 1em 0 0;
  }
  .db_patient_profile_top_inputs_address {
    width: 100%;
  }
}

@media only screen and (max-width: 550px) {
  .all_cb_label {
    font-size: 0.66em;
  }
  .accordion_services_container,
  .accordion__content__checkboxes,
  .location_form_container,
  ._subgroup_container,
  .all_categories_form_container {
    grid-template-columns: repeat(auto-fill, 100%) !important;
  }
}
/**********************Typical Mobile Media query********************/
@media only screen and (max-width: 500px) {
  .modify_schedule_prompt {
    margin: 0.5em;
  }
  .db_billing_tab {
    margin: 0 1em 0 0;
  }

  .doctor_card_container {
    grid-template-columns: repeat(auto-fill, 90%);
    place-content: center;
  }
  .patient_card_container,
  .add_patient_inputs,
  .add_admin_prompt {
    grid-template-columns: repeat(auto-fill, 100%);
  }
  .DB_doctors_top {
    flex-wrap: wrap;
    margin-bottom: 1em;
  }
  .db_thin_btn {
    margin-left: 0.5em;
  }
  .DB_doctors_top .all_drop_drown_container,
  .DB_doctors_top .add_button {
    width: 48% !important;
    margin-bottom: 0.5em;
  }
  .DB_doctors_top .add_button,
  .db_patient_files_container,
  .DB_schedule_top .doc_cards_search_bar {
    margin: 0;
  }
  .DB_doctors_top .react-select__control,
  .pharm_form_last_bottom .all_drop_drown_container,
  .react-select__control,
  .db_patient_files_container {
    width: 100% !important;
  }
  .db_doc_profile_table_cell_one > button {
    width: 9em;
  }
  .db_doc_profile_table {
    margin-right: 0.3em;
  }

  .hospital_doc_form_bottom form > div:last-child,
  .pharm_form_bottom form > div:last-child,
  .db_patient_profile_container_top_inputs {
    grid-template-columns: repeat(auto-fill, 100%);
  }
  .patient_status_drop .all_drop_drown_container {
    width: 7em !important;
  }
  .db_patient_profile_container_top_inputs {
    padding: 1.5em 0.5em;
  }
  .rentals_request_buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 0.4em;
  }
  .db_main_home_pat_break_down {
    width: 100%;
  }
  .db_settings_table_tabs .db_billing_tab:first-child {
    margin-left: 1em;
  }
  .orders_counter-container {
    grid-template-columns: repeat(auto-fill, 48%) !important;
  }
  .status_bl > span:before,
  .status_bl > span:after {
    width: 2em;
  }
}

/**********************DeskTop Media query********************/
@media only screen and (min-width: 768px) {
  .hamburger_btn {
    display: none;
  }
}
@media only screen and (max-width: 880px) {
  .all_categories_form_container {
    grid-template-columns: repeat(auto-fill, 100%) !important;
  }
}
.header_input {
  border: none;
  background: #f5f8fe;
}
.db_form_container_addpatient {
  border: none;
  border-radius: 0.24em;
  background: rgba(223, 232, 252, 0.3);
  border-top: none;
}
.red_placeholder::placeholder {
  color: red !important;
}

.patient_actions {
  border-radius: 7px;
}
@media only screen and (min-width: 900px) {
  .patient_actions_container {
    display: flex;
    justify-content: space-evenly;
    flex-grow: 1;
    font-family: SofiaProMedium;
  }
}

.patient_actions_button {
  width: 100%;
}
.vitals-card {
  background: #f3f4f8;
  border-radius: 10px;
  text-align: center;
  margin: 0 6px 0 6px;
}
.vitals-card div p {
  line-height: 13px !important;
}
.green_text {
  color: #22d389;
  font-size: 16px;
}

.vital-head {
  font-family: SofiaProBold;
  font-size: 16px;
}
.settings_checkbox input {
  height: 15px;
}
.settings_checkbox Label {
  font-size: 16px;
  font-family: Sofia Pro;
  font-style: normal;
  font-weight: 500;

  line-height: 22px;
  /* identical to box height */

  /* Blue */
  font-family: SofiaProMedium;
  color: #2254d3;
}

.settings_checkbox input {
  height: 24px;
  margin-bottom: 1em;
}

.general_settings_input {
  width: 100%;
  display: grid;
  display: -moz-grid;
  place-content: flex-start;
  grid-gap: 0.5em;
  grid-template-columns: repeat(auto-fill, 28%);
  padding-bottom: 2.2em;
}
.currency {
  width: 400px;
  margin-top: 22px;
  margin-bottom: 50px;
}

.general_settings_form div {
  margin-top: 17px;
}
.room_space {
  margin-left: -40px;
}
.room_space_container {
  padding-top: 12px;
  background: rgba(223, 232, 252, 0.3);
  border-radius: 15px;
  cursor: pointer;
  width: 205px;
  text-align: center;
  height: 327px;
}
.room_space_container h5 {
  background: #dfe8fc;
  border-radius: 20px;
  padding: 8px 0px 8px 0px;
  margin: 14px 14px 0px 14px;
  font-family: SofiaProMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 0.86em;
  line-height: normal;
  text-align: center;
  color: #2254d3;
}
.cancellation {
  padding-left: 30px;
  padding-top: 16px;
  margin-bottom: 6px;
}
.cancellation input {
  width: 91px;

  box-sizing: border-box;
  border-radius: 5px;
}

.cancellation input:hover {
  border: 1px solid #2254d3;
}
.cancellation h5 {
  padding-top: 16px;
}

.cancellation label {
  font-family: SofiaProMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 29px;
  margin-left: 16px;
  /* identical to box height */

  /* Label & subheading */

  color: #2254d3;
}
.consulting_schedule_container {
  display: flex;
  justify-content: space-between;
}
.consulting_schedule_overall {
  width: 100%;
}
.sessions_container {
  width: 215px;

  background: #dfe8fc;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
}
.date_container {
  margin-left: 20px;
  margin-right: 10px;
}
.time_container h6 {
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 125% */
  font-family: SofiaProRegular;
  text-align: center;
}

.doctor_on_duty {
  background: #ffebd9;
  border-radius: 10px;
  margin-bottom: 4px;
  height: 53px;
  display: flex;
  justify-content: space-between;
}
.doctor_on_duty h5 {
  color: #5843be;
}
.doctor_on_duty_container > div:nth-of-type(odd) {
  background: #eeeaff;
}
.patient_information_container > div:nth-of-type(even) {
  background: #ffebd9;
}
.bold {
  padding-left: 8px;
  color: #071232;
}
.doctor_on_duty h6 {
  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 138.5%;
  color: #5843be;
  margin-left: 10px;
}
.patient_information_card {
  background: #eeeaff;
  border-radius: 10px;
  margin-bottom: 4px;
  height: 53px;
  display: flex;
  justify-content: space-between;
}

.patient_information_card h5 {
  font-family: SofiaProBold;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 23px;
  /* identical to box height */
  color: #5843be;
}

.patient_information_card p {
  font-family: SofiaProMedium;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138.5%;
  color: #5843be;
}

.consulting_dropdown {
  margin-bottom: 8px;
}
.stocked-products div {
  display: flex;
  margin-top: -8px;
}
.pharm_settings_checkbox {
  margin-right: 24px;
}
.small_input {
  width: 90px;
}
.small_button_container {
  margin-left: 36px;
  display: flex;
}
.small_button_container h6 {
  font-size: 16px;
  line-height: 16px;
  color: #8e919c;
}
.stocked-products p {
  font-family: SofiaProBold;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;
  color: #2254d3;
  margin-top: 8px;
  margin-right: 32px;
  width: 183px;
}
.dd-list {
  background-color: #ffffff;
  height: 200px;
  position: absolute;
  border-radius: 10px;
  width: 162px;
  z-index: 1000;
  right: 5px;
}
.dd-list-item {
  background-color: #ffffff;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  font-family: SofiaProRegular;
  z-index: 100;
  color: #071232;
}
.dd-list-item:hover {
  background-color: #245de8;
}
.dropdown_flex_containers {
  display: flex;
}
.dropdown_flex_containers div {
  width: 50%;
}

.dropdown_input {
  margin-top: 1.5em;
}
.dropdown_checkbox {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}
.note_textarea {
  margin-bottom: 1em;
  height: 71px;
  margin-top: -12px;
}
.body_mt {
  margin-top: 14px;
}
.doctor_note_ {
  height: 80px;
  margin-top: 16px;
  margin-bottom: 8px;
}
.bulk_message_input {
  height: 152px;
}
.activity_name {
  width: 235px;
  margin-top: 13px;
}
.activity_amount {
  width: 124px;
}
.bill_category {
  width: 130px;
  margin-top: 70px;
}
.activity {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  margin-top: 16px;
}
.bill {
  background: rgba(223, 232, 252, 0.3);
  border-radius: 15px;
}
.bill_category {
  margin-bottom: 16px;
}
.stock_level_reminder {
  margin-top: 0 !important;
}
.week_button {
  background-color: #071232;
  border-radius: 10px;
  width: 8em;
}
.auto_redeem {
  margin-top: 12px;
}
.center {
  text-align: center;
  padding-left: 28px;
}
.pharm_billing_container {
  margin: 2em 0;
  display: grid;
  place-content: flex-start;
  grid-gap: 0.7em;
  grid-template-columns: repeat(auto-fill, 24%) !important;
}
.pharm_billing {
  background: #f3f4f8;
  border-radius: 15px;
  padding: 8px 16px 8px 16px;
}
.pharm_billing h4 {
  font-family: Metropolis-Black;
  font-weight: normal;
  font-size: 1.2em;
  color: #071232;
}
.pharm_billing h6 {
  font-family: Metropolis;
  font-weight: 500;
  font-size: 0.8em;
  color: #2254d3;
}
.pharm_billing_header {
  color: #2254d3;
}
.edit_input_two {
  padding-top: 16px;
}
.edit_input_text_container h5 {
  font-size: 10px;
  line-height: 19px;
  color: #828282;
}
.edit_input_text_container {
  font-size: 0.76em;
  line-height: 22px;

  background: #dfe8fc;
  border-radius: 0.24em;
  margin-top: 8px;
}
.pharm_locations_card {
  background: #dfe8fc;
  border-radius: 5px;
  padding: 4px 18px 50px 16px;
}
.prompt_header_form div {
  width: 100px;
}
.blue_dropdown_border Select {
  border: blue;
}
.dbod_title {
  display: flex;
  background: #f5f8fe !important;
  padding-bottom: 0 !important;
}
.dbod_title h5 {
  font-size: 0.667em;
  line-height: 17px;
  color: #071232;
}
.dbod_title h6 {
  margin-top: 4px;
  font-size: 14px;
  line-height: 17px;
  color: #2254d3;
}
.black {
  background-color: #000000 !important;
}
.notification_icon_text {
  font-size: 11px;
  margin-left: -12px;
  letter-spacing: 0.1em;
  color: #8e919c;
  margin-top: 2em;
}
.notification_icon_container {
  width: 54px;
  height: 62px;
}