@mixin normal_text($color, $transform) {
  /* Normal text */

  font-family: SofiaProMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 0.77em;
  line-height: 1em;
  /* identical to box height */

  /* Blue */

  color: $color;
  text-transform: $transform;
}
@mixin heading($color, $transform) {
  /* Heading */

  font-family: SofiaProBold;
  font-style: normal;
  font-weight: bold;
  font-size: 1.14em;
  line-height: 1.62em;
  /* identical to box height */

  /* Black */

  color: $color;
  text-transform: $transform;
}
@mixin label_subheading($transform, $color) {
  /* Label & subheading */

  font-family: SofiaProRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 0.667em;
  line-height: 0.9em;
  /* identical to box height */

  /* Label & subheading */

  color: $color;
  text-transform: $transform;
}
@mixin btn_text() {
  /* Btn text */

  font-family: SofiaProMedium;
  font-style: normal;
  font-weight: normal;
  font-size: 0.76em;
  line-height: 1.1em;
  /* identical to box height */

  color: #ffffff;
  margin-bottom: 0;
}
