/** @format */

$font-weights: (
  "light": 400,
  "regular": 600,
  "medium": 700,
  "bold": 800,
  "bolder": 900,
);
// $font-family: "Source Sans Pro", sans-serif;
$font-family: "Nunito Sans", sans-serif;
$font-seconday: "Nunito Sans", sans-serif;
$root-size: 16;
$responsive: (
  // "xs": 425px,
  "sm": 500px,
  "md": 768px,
  "lg": 970px,
  "xl": 1200px,
  "xxl": 1600px
);

$padding: (
  0: 0,
  1: 0.3rem,
  2: 0.6rem,
  3: 0.8rem,
  4: 1.6rem,
  5: 2.4rem,
);

$colors: (
  "primary": #2254d3,
  "second-blue": #3865d7,
  "light-blue": #dfe8fc,
  "purple": #9b51e0,
  "purple-light": #a562e3,
  "primary-light": #f3f6ff,
  "success": #34c073,
  "dark": #071232,
  "dark-50": #828282,
  "white": #ffffff,
  "light": #60677b,
  "grey": #bdbdbd,
  "danger": #eb5757,
  "carton": #ffd8b5,
);
