/** @format */

@mixin test($break-point, $res) {
  @each $key, $value in $responsive {
    @if $key == $res {
      @media screen and ($break-point: $value) {
        @content;
      }
    }
  }
}

@mixin media-breakpoint-up($res) {
  @include test(min-width, $res) {
    @content;
  }
}

@mixin media-breakpoint-down($res) {
  @include test(max-width, $res) {
    @content;
  }
}

@mixin flexCalculator($var) {
  // position: relative;
  width: 100%;
  flex: 0 0 (calc(100/12) * $var * 1%);
  max-width: (calc(100/12) * $var * 1%);
  padding-left: 1rem;
  padding-right: 1rem;
}
